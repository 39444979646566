// export const getUserId = () => {
//     return sessionStorage.getItem('user_id');
//     // return localStorage.getItem('user_id');
// };

// export const getCompanyId = () => {
//     return sessionStorage.getItem('company_id');
//     // return localStorage.getItem('company_id');

// };



export const getUserId = () => {
    // Retrieve user_id from sessionStorage
    const userId = sessionStorage.getItem('user_id');
  
    // Parse userId to integer
    const parsedId = parseInt(userId, 10);
  
    // Check if parsedId is a valid integer
    if (Number.isInteger(parsedId)) {
      console.log("User ID (Parsed):", parsedId);
      return parsedId;
    } else {
      console.warn("Invalid user_id detected, using fallback value of 1");

      localStorage.removeItem('token');
      alert("You have encountered an error. Please Login again");
      window.location.href = '/'; // Redirect to the root URL after logout
      return;

      // return 1; // Fallback value if parsing fails or is invalid
    }
  };


  export const getCompanyId = () => {
    // Retrieve company_id from sessionStorage
    const companyId = sessionStorage.getItem('company_id');
  
    // Parse userId to integer
    const parsedId = parseInt(companyId, 10);
  
    // Check if parsedId is a valid integer
    if (Number.isInteger(parsedId)) {
      console.log("Company ID (Parsed):", parsedId);
      return parsedId;
    } else {
      localStorage.removeItem('token');
      alert("You have encountered an error. Please Login again");
      window.location.href = '/'; // Redirect to the root URL after logout
      console.warn("Invalid company_id detected, using fallback value of 1");

      return; // Fallback value if parsing fails or is invalid
    }
  };



