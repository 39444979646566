import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import api from '../../api';
import { getUserId, getCompanyId } from '../../utils/auth';

const CreateJobLink = () => {
  const userId = getUserId();
  const companyId = getCompanyId();

  const [avatar, setAvatar] = useState(null);
  const [difficulty, setDifficulty] = useState(1);
  const [interviewPlan, setInterviewPlan] = useState(null);
  const [maxApplicants, setMaxApplicants] = useState(0);
  const [avatars, setAvatars] = useState([]);
  const [interviewPlans, setInterviewPlans] = useState([]);
  const { jobId } = useParams(); // Get jobId from URL parameters

  const navigate = useNavigate();
  // Fetch data for avatars and interview plans
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [avatarResponse, interviewPlanResponse] = await Promise.all([
          api.get('avatars/', { params: { ordering: 'id' } }),
          api.get('interviewplans/', { params: { ordering: 'id' } }),
        ]);

        setAvatars(avatarResponse.data.results.map(avatar => ({
          value: avatar.id,
          label: avatar.avatar_name,
        })));

        setInterviewPlans(interviewPlanResponse.data.results.map(plan => ({
          value: plan.id,
          label: plan.plan_name,
        })));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    const userId = getUserId(); 
    const companyId = getCompanyId();

    e.preventDefault();
    const jobLinkData = {
      job_id: jobId,
      avatar_id: avatar,
      interview_plan_id: interviewPlan,
      user_id: userId,
      difficulty: difficulty,
      max_applicants: maxApplicants,
      usercompany: companyId,
    };

    try {
      const response = await api.post('create-joblink/', jobLinkData);
      if (response.data.success) {
        alert('Job link created successfully!');
        navigate('/search-jobs'); // Navigate back to search jobs page
      } else {
        alert(response.data.error || 'An error occurred while creating the job link.');
      }
    } catch (error) {
      console.error('Error creating job link:', error);
      alert('Error creating job link.');
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h2>Create Job Link</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="avatar">
              <Form.Label>Avatar</Form.Label>
              <Select
                name="avatar"
                value={avatars.find(option => option.value === avatar)}
                onChange={option => setAvatar(option.value)}
                options={avatars}
                isSearchable
                placeholder="Select Avatar"
              />
            </Form.Group>

            <Form.Group controlId="interviewPlan">
              <Form.Label>Interview Plan</Form.Label>
              <Select
                name="interviewPlan"
                value={interviewPlans.find(option => option.value === interviewPlan)}
                onChange={option => setInterviewPlan(option.value)}
                options={interviewPlans}
                isSearchable
                placeholder="Select Interview Plan"
              />
            </Form.Group>

            <Form.Group controlId="difficulty">
              <Form.Label>Difficulty</Form.Label>
              <Form.Control
                as="select"
                value={difficulty}
                onChange={(e) => setDifficulty(parseInt(e.target.value))}
              >
                <option value={1}>Normal</option>
                <option value={2}>Hard</option>
                <option value={3}>Very Hard</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="maxApplicants">
              <Form.Label>Max Applicants</Form.Label>
              <Form.Control
                type="number"
                value={maxApplicants}
                onChange={(e) => setMaxApplicants(parseInt(e.target.value))}
                placeholder="Enter max applicants (0 for no limit)"
                min={0}
                max={250}
                defaultValue={150}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Create Job Link
            </Button>
            <Button
              variant="secondary"
              onClick={() => navigate('/search-jobs')}
              className="mt-3 ml-3"
            >
              Cancel
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateJobLink;
