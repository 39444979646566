import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';  // Custom styles, if any
import './App.css';  // Custom styles, if any


import Login from './components/Auth/Login';
import ForgotPassword from './components/Auth/ForgotPassword';
import SignupPage from './components/Auth/SignupPage';
import Dashboard from './components/Dashboard/Dashboard';
import AddCandidate from './components/Candidates/AddCandidate';
import SearchCandidates from './components/Candidates/SearchCandidates';
import EditCandidate from './components/Candidates/EditCandidate';
import CandidateDetails from './components/Candidates/CandidateDetails';
import AddJob from './components/Jobs/AddJob';
import SearchJobs from './components/Jobs/SearchJobs';
import CreateJobLink from './components/Jobs/CreateJobLink'; 
import EditJob from './components/Jobs/EditJob';
import JobDetails from './components/Jobs/JobDetails';
import CreateInterview from './components/Interviews/CreateInterview';
import SearchInterview from './components/Interviews/SearchInterview';
import EditInterview from './components/Interviews/EditInterview';
import Report1 from './components/Reports/Report1';
import Report2 from './components/Reports/Report2';
import Report3 from './components/Reports/Report3';
import Report4 from './components/Reports/Report4';
import SearchUsers from './components/Users/SearchUsers';
import AddUser from './components/Users/AddUser';
import EditUser from './components/Users/EditUser';
import AvatarDetails from './components/Avatars/AvatarDetails';
import AddAvatar from './components/Avatars/AddAvatar';
import EditAvatar from './components/Avatars/EditAvatar';
import SearchAvatars from './components/Avatars/SearchAvatars';
import BitsoftLogo from './assets/images/Bitsoft-LogoSM.png'; // Add this line to import the image
import CreateInterviewPlan from './components/InterviewPlan/CreateInterviewPlan';
import EditInterviewPlan from './components/InterviewPlan/EditInterviewPlan';
import SearchInterviewPlan from './components/InterviewPlan/SearchInterviewPlan';
import CommandCenter from './components/CommandCenter/CommandCenter';
import InterviewDetail from './components/CommandCenter/InterviewDetail';
import InterviewPlanDetails from './components/InterviewPlan/PlanDetailsModal'; // Ensure this is correctly imported
import PasswordReset from './components/Auth/PasswordReset';
import PublicInterviewDetail from './components/CommandCenter/PublicInterviewDetail';
import ApplyJob from './components/External/applyjob';
import ConfirmationPage from './components/External/ConfirmationPage';  // Adjust the path based on your folder structure


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isBaap, setIsBaap] = useState(false); // baap means User is a Superuser
  

  useEffect(() => {
    const token = localStorage.getItem('token');
    const baap = localStorage.getItem('is_baap');
    if (token) {
      setIsAuthenticated(true);
    }
    if (baap) {
      setIsBaap(baap);
    }

  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    window.location.href = '/'; // Redirect to the root URL after logout

  };

  const plainNavbarPages = ['/', '/applyjob', '/forgot-password', '/confirmation', '/reset-password'];
  const shouldShowFooter = isAuthenticated || plainNavbarPages.some(page => window.location.pathname.startsWith(page));


  return (
    <Router>
      <div>
        {isAuthenticated && (
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
                <img src={BitsoftLogo} alt="Bitsoft Logo" height="40" />
              </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" href="/command-center">Command Center</a>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Candidates
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a className="dropdown-item" href="/add-candidate">Add Candidate</a></li>
                      <li><a className="dropdown-item" href="/search-candidates">Search Candidates</a></li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Jobs
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a className="dropdown-item" href="/add-job">Add Job</a></li>
                      <li><a className="dropdown-item" href="/search-jobs">Search Jobs</a></li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Interviews
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a className="dropdown-item" href="/create-interview">Create Interview</a></li>
                      <li><a className="dropdown-item" href="/search-interview">Search Interviews</a></li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Interview Plans
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a className="dropdown-item" href="/create-interview-plan">Add Interview Plan</a></li>
                      <li><a className="dropdown-item" href="/search-interview-plan">Search Interview Plan</a></li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Reports
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a className="dropdown-item" href="/report1">Report 1</a></li>
                      <li><a className="dropdown-item" href="/report2">Report 2</a></li>
                      <li><a className="dropdown-item" href="/report3">Report 3</a></li>
                      <li><a className="dropdown-item" href="/report4">Report 4</a></li>
                    </ul>
                  </li>
                  {isBaap && (
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Admin
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="/add-user">Add User</a></li>
                        <li><a className="dropdown-item" href="/search-users">Search Users</a></li>
                        <li><a className="dropdown-item" href="/add-avatar">Add Avatar</a></li>
                        <li><a className="dropdown-item" href="/search-avatars">Search Avatars</a></li>
                      </ul>
                    </li>
                  )}
                  </ul>
                <button className="btn btn-outline-light ms-auto" onClick={handleLogout}>Logout</button>
              </div>
            </div>
          </nav>
        )
        }

        {!isAuthenticated && 
          plainNavbarPages.some(page => window.location.pathname.startsWith(page)) && (
            <nav className="navbar navbar-light bg-light">
              <div className="container-fluid">
                <a className="navbar-brand" href="http://www.bitsoft.com">
                  <img src={BitsoftLogo} alt="Bitsoft Logo" height="50px" />
                </a>
              </div>
            </nav>
          )
        }

        <div style={{ marginTop: '30px', paddingBottom: '20px' }}>  {/* Added paddingBottom to give space above the footer */}

        <Routes>
          <Route path="/" element={<Login onLogin={handleLogin} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />} />
          <Route path="/add-candidate" element={isAuthenticated ? <AddCandidate /> : <Navigate to="/add-candidate" />} />
          <Route path="/search-candidates" element={isAuthenticated ? <SearchCandidates /> : <Navigate to="/search-candidates" />} />
          <Route path="/edit-candidate/:id" element={isAuthenticated ? <EditCandidate /> : <Navigate to="/edit-candidate" />} />
          <Route path="/add-job" element={isAuthenticated ? <AddJob /> : <Navigate to="/add-job" />} />
          <Route path="/search-jobs" element={isAuthenticated ? <SearchJobs /> : <Navigate to="/search-jobs" />} />
          <Route path="/edit-job/:id" element={isAuthenticated ? <EditJob /> : <Navigate to="/edit-job/:id" />} />
          <Route path="/create-interview" element={isAuthenticated ? <CreateInterview /> : <Navigate to="/create-interview" />} />
          <Route path="/search-interview" element={isAuthenticated ? <SearchInterview /> : <Navigate to="/search-interview" />} />
          <Route path="/edit-interview/:id" element={isAuthenticated ? <EditInterview /> : <Navigate to="/edit-interview/:id" />} />
          <Route path="/report1" element={isAuthenticated ? <Report1 /> : <Navigate to="/report1" />} />
          <Route path="/report2" element={isAuthenticated ? <Report2 /> : <Navigate to="/report2" />} />
          <Route path="/report3" element={isAuthenticated ? <Report3 /> : <Navigate to="/report3" />} />
          <Route path="/report4" element={isAuthenticated ? <Report4 /> : <Navigate to="/report4" />} />
          <Route path="/search-users" element={isAuthenticated ? <SearchUsers /> : <Navigate to="/search-users" />} />
          <Route path="/add-user" element={isAuthenticated ? <AddUser /> : <Navigate to="/add-user" />} />
          <Route path="/edit-user/:id" element={isAuthenticated ? <EditUser /> : <Navigate to="/edit-user/:id" />} />
          <Route path="/avatar-details/:id" element={isAuthenticated ? <AvatarDetails /> : <Navigate to="/avatar-details/:id" />} />
          <Route path="/add-avatar" element={isAuthenticated ? <AddAvatar /> : <Navigate to="/add-avatar" />} />
          <Route path="/edit-avatar/:id" element={isAuthenticated ? <EditAvatar /> : <Navigate to="/edit-avatar/:id" />} />
          <Route path="/search-avatars" element={isAuthenticated ? <SearchAvatars /> : <Navigate to="/search-avatars" />} />
          <Route path="/create-interview-plan" element={isAuthenticated ? <CreateInterviewPlan /> : <Navigate to="/create-interview-plan" />} />
          <Route path="/search-interview-plan" element={isAuthenticated ? <SearchInterviewPlan /> : <Navigate to="/search-interview-plan" />} />
          <Route path="/command-center" element={isAuthenticated ? <CommandCenter /> : <Navigate to="/command-center" />} />
          <Route path="/interview/:id" element={isAuthenticated ? <InterviewDetail /> : <Navigate to="/" />} />
          <Route path="/interview-plan-details/:id" element={<InterviewPlanDetails />} /> {/* Ensure this is correct */}
          <Route path="/edit-interview-plan/:id" element={isAuthenticated ? <EditInterviewPlan /> : <Navigate to="/edit-interview-plan/:id" />} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password/:identifier" element={<PasswordReset />} />
          <Route path="/candidate-details/:id" element={<CandidateDetails />} />
          <Route path="/job-details/:id" element={<JobDetails />} />
          <Route path="/public-interview/:linkId" element={<PublicInterviewDetail />} />
          <Route path="/create-joblink/:jobId" element={<CreateJobLink />} />  {/* Route for CreateJobLink */}
          <Route path="/applyjob/:link" element={<ApplyJob />} />
          <Route path="/confirmation" element={<ConfirmationPage />} />
          <Route path="/signup" element={<SignupPage />} />
          </Routes>
          </div>

         {/* Footer */}
        {!isAuthenticated && 
          plainNavbarPages.some(page => window.location.pathname.startsWith(page)) && (
          <footer className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <span className="navbar-text mx-auto text-center">
                &copy; 2024 Bitsoft International, Inc. All Rights Reserved.
              </span>
            </div>
          </footer>
        )}

      </div>
    </Router>
  );
}

export default App;