import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

const PlanDetailsModal = ({ show, handleClose, planDetails, error }) => {
  const getActionDescription = (actionid) => {
    switch(actionid) {
      case 1:
        return 'Speak Text Only';
      case 2:
        return 'Speak and Listen';
      case 3:
        return 'AI Response to Candidate';
      case 4:
        return 'Reserved. Do Not Use';
      default:
        return 'Unknown Action';
    }
  };


  const getTranslationDescription = (id) => {
    switch(id) {
      case 0:
        return 'No';
      case 1:
        return 'Yes';
      default:
        return 'No';
    }
  };


  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Interview Plan Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? (
          <p>{error}</p>
        ) : planDetails && planDetails.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {planDetails.map((instruction, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ textAlign: 'left' }}>
                    <strong>Explanation:</strong> {instruction.explaination}<br />
                    <strong>Action:</strong> {getActionDescription(instruction.actionid)}<br />
                    <strong>AI User:</strong> {instruction.aiuser === 1 ? 'System' : 'User'}<br />
                    <strong>AI User Instruction:</strong> {instruction.aiuser_instruction}<br />
                    <strong>AI User 1:</strong> {instruction.aiuser1 === 1 ? 'System' : 'User'}<br />
                    <strong>AI User Instruction 1:</strong> {instruction.aiuser1_instruction}<br />
                    <strong>Tentative Duration:</strong> {instruction.tentative_duration}<br />
                    <strong>Translate:</strong> {getTranslationDescription(instruction.translate)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>Loading...</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlanDetailsModal;
