import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const ConfirmationPage = () => {
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
    },
    card: {
      padding: '2rem',
      borderRadius: '12px',
      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
    },
    checkIcon: {
      color: '#28a745',
      fontSize: '5rem',
      marginBottom: '1rem',
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: '600',
      marginBottom: '1rem',
    },
    text: {
      fontSize: '1.25rem',
      fontWeight: '300',
      color: '#6c757d',
    },
  };

  return (
    <Container style={styles.container}>
      <Row className="justify-content-md-center">
        <Col md="8">
          <Card style={styles.card}>
            <Card.Body className="text-center">
              <i className="fas fa-check-circle" style={styles.checkIcon}></i>
              <Card.Title style={styles.title}>Application Submitted</Card.Title>
              <Card.Text style={styles.text}>
                Your application has been submitted successfully. You will receive an interview invitation email shortly.
                Please check your spam folder if you do not receive the email in next few minutes.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfirmationPage;
