import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Form, InputGroup, Pagination } from 'react-bootstrap';
import api from '../../api';
import moment from 'moment'; // Import moment.js for date formatting
import '../../styles.css';
import { getUserId, getCompanyId} from '../../utils/auth'; // Import the utility function


const SearchCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCandidates();
  }, [currentPage, searchTerm]);

  const fetchCandidates = async () => {


    try {
      const response = await api.get(`api/candidates/`, {
        params: {
          page: currentPage,
          page_size: 15,  // Adjust the page size as needed
          search: searchTerm,
          ordering: '-id',
          usercompany: getCompanyId(),  // Add usercompany parameter
        },
      });

      console.log(response.data);

      setCandidates(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10)); // Assuming page size is 10
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  };


  const handleSearch = async (e) => {
    e.preventDefault();
    setCurrentPage(1); // Reset to first page for new search
    fetchCandidates();  // Trigger the fetch with the new search term
  };

  const handleEdit = (id) => {
    navigate(`/edit-candidate/${id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  return (
    <div className="container mt-5">
    <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Search Candidates</h2>

        {/* Create Interview Button */}
        <Button
          variant="success"
          onClick={() => navigate('/add-candidate')}
          style={{ minWidth: '130px' }}
        >
          <i className="bi bi-plus-lg me-2"></i> {/* Bootstrap icon with right margin */}
          Candidate
        </Button>
      </div>      
      <Form onSubmit={handleSearch}>
        <InputGroup className="mb-4">
          <Form.Control
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by name"
          />
          <Button variant="primary" type="submit">
            <i className="bi bi-search me-2"></i> Search
          </Button>
        </InputGroup>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Skills</th>
            <th>Phone</th>
            <th>Alternate Phone</th>
            <th>LinkedIn Profile</th>
            <th>Created by</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {candidates.map((candidate) => (
            <tr key={candidate.id}>
              <td>{candidate.first_name}</td>
              <td>{candidate.last_name}</td>
              <td>{candidate.email}</td>
              <td>{candidate.skills}</td>
              <td>{candidate.phone}</td>
              <td>{candidate.phone2}</td>
              <td>
                <a href={candidate.linkedin_profile} target="_blank" rel="noopener noreferrer">
                  LinkedIn
                </a>
              </td>
              {/* <td>{candidate.created_by}</td> */}
              <td>{candidate.created_by_first_name && candidate.created_by_last_name ? `${candidate.created_by_first_name} ${candidate.created_by_last_name}` : 'N/A'}</td>
              <td>{moment(candidate.created_at).format('MM/DD/YY HH:mm')}</td>
              <td>
                <Button size="sm" variant="primary" onClick={() => handleEdit(candidate.id)}>
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Pagination */}
      {totalPages > 1 && (
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

          {/* Logic for showing limited page numbers */}
          {currentPage > 2 && <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>}
          {currentPage > 3 && <Pagination.Ellipsis />}

          {/* Display a range of pages around the current page */}
          {Array.from({ length: totalPages }, (_, i) => i + 1)
            .filter((page) => page >= currentPage - 1 && page <= currentPage + 1)
            .map((page) => (
              <Pagination.Item key={page} active={page === currentPage} onClick={() => handlePageChange(page)}>
                {page}
              </Pagination.Item>
            ))}

          {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
          {currentPage < totalPages - 1 && (
            <Pagination.Item onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>
          )}

          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      )}    </div>
  );
};

export default SearchCandidates;

