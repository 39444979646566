// src/components/Auth/PasswordReset.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api'; // Your configured Axios instance
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';

const PasswordReset = () => {
    const { identifier } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [uidb64, setUidb64] = useState(null);
    const [token, setToken] = useState(null);
    const navigate = useNavigate();

    console.log(identifier);


    useEffect(() => {
        const validateIdentifier = async () => {
            try {
                const response = await api.get(`/validate-reset/${identifier}/`);
                setUidb64(response.data.uidb64);
                setToken(response.data.token);
            } catch (error) {
                console.error('Invalid or expired reset identifier.');
                navigate('/');  // Redirect to home or error page
            }
        };

        validateIdentifier();
    }, [identifier, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        try {
          const response = await api.post(`/reset-password/${identifier}/`, {
            new_password: newPassword,
          });
            alert('Password reset successful');
            navigate('/');
        } catch (error) {
            console.error('Error resetting password:', error);
            alert('Error resetting password');
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col md="6">
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-center mb-4">Reset Password</Card.Title>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formNewPassword" className="mb-3">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter new password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formConfirmPassword" className="mb-3">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm new password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="w-100" disabled={!uidb64 || !token}>
                                    Reset Password
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default PasswordReset;
