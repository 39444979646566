// src/components/Jobs/EditJob.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import api from '../../api';
import { getUserId, getCompanyId } from '../../utils/auth'; // Import the utility function

const EditJob = () => {
  const { id } = useParams();
  const userId = getUserId();
  const companyId = getCompanyId();
    

  const [job, setJob] = useState({
    title: '',
    description: '',
    location: '',
    skills: '',
    duration: '',
    rate: '',
    company: '',
    posted_by:userId,
    usercompany: companyId,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await api.get(`jobs/${id}/`);
        setJob(response.data);
      } catch (error) {
        console.error('Error fetching job:', error);
      }
    };

    fetchJob();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJob((prevJob) => ({
      ...prevJob,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const userId = getUserId(); // Retrieve the user ID
    // const companyId = getCompanyId();
    
    const jobData = {
        ...job,
        posted_by: userId, // Add the user ID to the job data
        companyId: companyId,
    };

    try {
        await api.put(`jobs/${id}/`, jobData);
        alert('Job updated successfully');
        navigate('/search-jobs');
    } catch (error) {
        console.error('Error updating job:', error);
        alert('Error updating job');
    }
};
  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="8">
          <Card>
            <Card.Body>
              <Card.Title className="text-center mb-4">Edit Job</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={job.title}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={job.description}
                    onChange={handleChange}
                    required
                    rows={10}
                  />
                </Form.Group>
                <Form.Group controlId="location" className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={job.location}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="skills" className="mb-3">
                  <Form.Label>Skills</Form.Label>
                  <Form.Control
                    type="text"
                    name="skills"
                    value={job.skills}
                    onChange={handleChange}
                    placeholder="Enter skills as comma-separated values (e.g., JavaScript,React,Node.js)"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="duration" className="mb-3">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    type="text"
                    name="duration"
                    value={job.duration}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="rate" className="mb-3">
                  <Form.Label>Rate</Form.Label>
                  <Form.Control
                    type="text"
                    name="rate"
                    value={job.rate}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="company" className="mb-3">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    value={job.company}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 mt-3">
                  Update Job
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditJob;
