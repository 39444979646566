import React, { useState, useEffect } from 'react';
import { Table, Button, Form, InputGroup, Pagination } from 'react-bootstrap';
import api from '../../api';
import PlanDetailsModal from './PlanDetailsModal';
import { useNavigate } from 'react-router-dom';

const SearchInterviewPlan = () => {
  const [plans, setPlans] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [planDetails, setPlanDetails] = useState([]);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPlans(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const fetchPlans = async (page, search = '') => {
    try {
      const response = await api.get('/api/list_interview_plans/', {
        params: {
          page: page,
          page_size: 10,  // Customize page size as needed
          search: search,
        },
      });
      setPlans(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  const fetchPlanDetails = async (planId) => {
    try {
      const response = await api.get(`/api/plan_instructions/${planId}/`);
      setPlanDetails(response.data);
      setShowModal(true);
    } catch (error) {
      setError('Error fetching plan details');
      setShowModal(true);
    }
  };

  const handleShowDetails = (planId) => {
    setSelectedPlan(planId);
    fetchPlanDetails(planId);
  };

  const handleEditPlan = (planId) => {
    navigate(`/edit-interview-plan/${planId}`);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPlanDetails([]);
    setError('');
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => (
    <Pagination className="justify-content-center">
      <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
      <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

      {currentPage > 2 && <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>}
      {currentPage > 3 && <Pagination.Ellipsis />}

      {Array.from({ length: totalPages }, (_, i) => i + 1)
        .filter((page) => page >= currentPage - 1 && page <= currentPage + 1)
        .map((page) => (
          <Pagination.Item key={page} active={page === currentPage} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        ))}

      {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
      {currentPage < totalPages - 1 && (
        <Pagination.Item onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>
      )}

      <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
      <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    </Pagination>
  );

  return (
    <div className="container mt-5">
    <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Search Interview Plans</h2>

        {/* Create Interview Button */}
        <Button
          variant="success"
          onClick={() => navigate('/create-interview-plan')}
          style={{ minWidth: '100px' }}
        >
          <i className="bi bi-plus-lg me-2"></i> {/* Bootstrap icon with right margin */}
          Plan
        </Button>
      </div>
      <Form className="mb-4" onSubmit={(e) => e.preventDefault()}>
        <InputGroup>
          <Form.Control
            type="text"
            placeholder="Search plans"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <Button variant="primary" onClick={() => fetchPlans(1, searchTerm)}>
            <i className="bi bi-search me-2"></i> Search
          </Button>
        </InputGroup>
      </Form>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Plan Name</th>
            <th>Description</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {plans.length > 0 ? (
            plans.map(plan => (
              <tr key={plan.id}>
                <td>{plan.id}</td>
                <td>{plan.plan_name}</td>
                <td>{plan.description}</td>
                <td>{plan.created_by}</td>
                <td>{new Date(plan.created_at).toLocaleString()}</td>
                <td>
                  <Button size="sm" onClick={() => handleShowDetails(plan.id)}>Details</Button>
                  <Button size="sm" onClick={() => handleEditPlan(plan.id)} variant="warning">Edit</Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">No plans found</td>
            </tr>
          )}
        </tbody>
      </Table>

      {totalPages > 1 && renderPagination()}

      <PlanDetailsModal
        show={showModal}
        handleClose={handleCloseModal}
        planDetails={planDetails}
        error={error}
      />
    </div>
  );
};

export default SearchInterviewPlan;











// import React, { useState, useEffect } from 'react';
// import { Table, Button, Form, InputGroup, Pagination } from 'react-bootstrap';
// import api from '../../api';
// import PlanDetailsModal from './PlanDetailsModal';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate

// const SearchInterviewPlan = () => {
//   const [plans, setPlans] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [planDetails, setPlanDetails] = useState([]);
//   const [error, setError] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const navigate = useNavigate(); // Use useNavigate for navigation

//   useEffect(() => {
//     fetchPlans();
//   }, [currentPage]);

//   const fetchPlans = async () => {
//     try {
//       const response = await api.get(`/api/list_interview_plans/?page=${currentPage}&search=${searchTerm}`);
//       setPlans(response.data.results);
//       setTotalPages(Math.ceil(response.data.count / 10)); // Assuming page size is 10
//     } catch (error) {
//       console.error('Error fetching plans:', error);
//     }
//   };

//   const fetchPlanDetails = async (planId) => {
//     try {
//       const response = await api.get(`/api/plan_instructions/${planId}/`);
//       setPlanDetails(response.data);
//       setShowModal(true);
//     } catch (error) {
//       setError('Error fetching plan details');
//       setShowModal(true);
//     }
//   };

//   const handleShowDetails = (planId) => {
//     setSelectedPlan(planId);
//     console.log(selectedPlan);
//     fetchPlanDetails(planId);
//   };

//   const handleEditPlan = (planId) => {
//     navigate(`/edit-interview-plan/${planId}`); // Navigate to the edit page
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setPlanDetails([]);
//     setError('');
//   };

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//     setCurrentPage(1); // Reset to first page for new search
//     fetchPlans();
//   };

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const renderPagination = () => {
//     let items = [];
//     for (let number = 1; number <= totalPages; number++) {
//       items.push(
//         <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
//           {number}
//         </Pagination.Item>
//       );
//     }
//     return items;
//   };

//   return (
//     <div>
//       <h2>Interview Plans</h2>
//       <Form className="mb-4" onSubmit={(e) => e.preventDefault()}>
//         <InputGroup>
//           <Form.Control
//             type="text"
//             placeholder="Search plans"
//             value={searchTerm}
//             onChange={handleSearchChange}
//           />
//         </InputGroup>
//       </Form>
//       <Table striped bordered hover>
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Plan Name</th>
//             <th>Description</th>
//             <th>Created By</th>
//             <th>Created At</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {plans.map(plan => (
//             <tr key={plan.id}>
//               <td>{plan.id}</td>
//               <td>{plan.plan_name}</td>
//               <td>{plan.description}</td>
//               <td>{plan.created_by}</td>
//               <td>{new Date(plan.created_at).toLocaleString()}</td>
//               <td>
//                 <Button size="sm"  onClick={() => handleShowDetails(plan.id)}>Details</Button>
//                 <Button size="sm" onClick={() => handleEditPlan(plan.id)} variant="warning">Edit</Button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       <Pagination>{renderPagination()}</Pagination>

//       <PlanDetailsModal
//         show={showModal}
//         handleClose={handleCloseModal}
//         planDetails={planDetails}
//         error={error}
//       />
//     </div>
//   );
// };

// export default SearchInterviewPlan;
