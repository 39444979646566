import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from '../../api';

const SignupPage = () => {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [successData, setSuccessData] = useState(null); // To store success response
    const [error, setError] = useState(''); // To store error message

    useEffect(() => {
        const subscriptionId = searchParams.get('sub_id');
        if (!subscriptionId) {
            setError('Subscription ID is missing in the URL.');
            setLoading(false);
            return;
        }

        const signup = async () => {
            try {
                const response = await api.post('api/signup-from-subscription/', {
                    subscription_id: subscriptionId
                });
                // Set the success data from the response
                setSuccessData({
                    message: response.data.message,
                    companyName: response.data.company_id,
                    email: response.data.user_id,
                });
                setLoading(false);
            } catch (err) {
                setError(err.response?.data?.error || 'An error occurred.');
                setLoading(false);
            }
        };

        signup();
    }, [searchParams]);

    if (loading) return <div>Loading...</div>;

    return (
        <div
            style={{
                padding: '20px',
                maxWidth: '600px',
                margin: '50px auto',
                borderRadius: '10px',
                textAlign: 'center',
                backgroundColor: successData ? 'lightgreen' : 'lightcoral', // Green for success, red for error
                color: successData ? 'darkgreen' : 'white',
            }}
        >
            {successData ? (
                <>
                    <h2>Signup Successful!</h2>
                    <p>{successData.message}</p>
                    <p><strong>Company Name:</strong> {successData.companyName}</p>
                    <p><strong>Email:</strong> {successData.email}</p>
                    <p>
                        A confirmation email has been sent to your email address. Please check your inbox to log in.
                    </p>
                </>
            ) : (
                <>
                    <h2>Signup Failed</h2>
                    <p>{error}</p>
                </>
            )}
        </div>
    );
};

export default SignupPage;
