import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import api from '../../api';
import useDebounce from '../../hooks/useDebounce';

const EditInterview = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [interview, setInterview] = useState({
    candidate: null,
    job: null,
    avatar: null,
    difficulty: 1,
    interviewPlan: null,
  });

  const [candidates, setCandidates] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [interviewPlans, setInterviewPlans] = useState([]);
  const [searchTerms, setSearchTerms] = useState({
    candidate: '',
    job: '',
  });

  const debouncedCandidateSearch = useDebounce(searchTerms.candidate, 700);
  const debouncedJobSearch = useDebounce(searchTerms.job, 700);

  // Fetch initial interview data and set candidate/job options
  useEffect(() => {
    const fetchInterviewData = async () => {
      try {
        const response = await api.get(`interviews/${id}/`);
        const interviewData = response.data;

        setInterview({
          candidate: interviewData.candidate,
          job: interviewData.job,
          avatar: interviewData.avatar,
          difficulty: interviewData.difficulty,
          interviewPlan: interviewData.planid,
        });

        // Fetch specific candidate and job to add as initial options
        if (interviewData.candidate) {
          const candidateResponse = await api.get(`candidates/${interviewData.candidate}/`);
          setCandidates([{ value: candidateResponse.data.id, label: `${candidateResponse.data.first_name} ${candidateResponse.data.last_name}` }]);
        }
        if (interviewData.job) {
          const jobResponse = await api.get(`jobs/${interviewData.job}/`);
          setJobs([{ value: jobResponse.data.id, label: jobResponse.data.title }]);
        }

      } catch (error) {
        console.error('Error fetching interview data:', error);
      }
    };

    fetchInterviewData();
  }, [id]);

  // Fetch candidates based on debounced search term
  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await api.get('search/candidatesearch/', {
          params: { search: debouncedCandidateSearch },
        });
        setCandidates(response.data.map(candidate => ({
          value: candidate.id,
          label: candidate.label,
        })));
      } catch (error) {
        console.error('Error fetching candidates:', error);
      }
    };
    if (debouncedCandidateSearch) fetchCandidates();
  }, [debouncedCandidateSearch]);

  // Fetch jobs based on debounced search term
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await api.get('search/jobsearch/', {
          params: { search: debouncedJobSearch },
        });
        setJobs(response.data.map(job => ({
          value: job.id,
          label: job.label,
        })));
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };
    if (debouncedJobSearch) fetchJobs();
  }, [debouncedJobSearch]);

  // Fetch static data for avatars and interview plans
  useEffect(() => {
    const fetchStaticData = async () => {
      try {
        const [avatarResponse, interviewPlanResponse] = await Promise.all([
          api.get('avatars/'),
          api.get('interviewplans/'),
        ]);

        setAvatars(avatarResponse.data.results.map(avatar => ({
          value: avatar.id,
          label: avatar.avatar_name,
        })));

        setInterviewPlans(interviewPlanResponse.data.results.map(plan => ({
          value: plan.id,
          label: plan.plan_name,
        })));
      } catch (error) {
        console.error('Error fetching avatars or interview plans:', error);
      }
    };

    fetchStaticData();
  }, []);

  const handleSearchChange = (name, value) => {
    setSearchTerms(prevSearchTerms => ({
      ...prevSearchTerms,
      [name]: value,
    }));
  };

  const handleChange = (name, selectedOption) => {
    setInterview(prevInterview => ({
      ...prevInterview,
      [name]: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!interview.candidate || !interview.job) {
      alert("Please select both a candidate and a job.");
      return;
    }

    const interviewData = {
      candidate: interview.candidate,
      job: interview.job,
      avatar: interview.avatar,
      difficulty: interview.difficulty,
      planid: interview.interviewPlan,
    };

    try {
      await api.put(`interviews/${id}/`, interviewData, {
        headers: { 'Content-Type': 'application/json' },
      });
      alert('Interview updated successfully');
      navigate('/search-interview');
    } catch (error) {
      console.error('Error updating interview:', error.response ? error.response.data : error.message);
      alert('Error updating interview');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Edit Interview</h2>
      <Form onSubmit={handleSubmit} className="mt-4">
        <Form.Group controlId="candidate">
          <Form.Label>Candidate</Form.Label>
          <Select
            name="candidate"
            value={candidates.find(option => option.value === interview.candidate) || null}
            onInputChange={(value) => handleSearchChange('candidate', value)}
            onChange={(option) => handleChange('candidate', option)}
            options={candidates}
            isSearchable
            placeholder="Search and select Candidate"
          />
        </Form.Group>
        <Form.Group controlId="job">
          <Form.Label>Job</Form.Label>
          <Select
            name="job"
            value={jobs.find(option => option.value === interview.job) || null}
            onInputChange={(value) => handleSearchChange('job', value)}
            onChange={(option) => handleChange('job', option)}
            options={jobs}
            isSearchable
            placeholder="Search and select Job"
          />
        </Form.Group>
        <Form.Group controlId="interviewPlan">
          <Form.Label>Interview Plan</Form.Label>
          <Select
            name="interviewPlan"
            value={interviewPlans.find(option => option.value === interview.interviewPlan) || null}
            onChange={(option) => handleChange('interviewPlan', option)}
            options={interviewPlans}
            isSearchable
            placeholder="Search and select Interview Plan"
          />
        </Form.Group>
        <Form.Group controlId="avatar">
          <Form.Label>Avatar</Form.Label>
          <Select
            name="avatar"
            value={avatars.find(option => option.value === interview.avatar) || null}
            onChange={(option) => handleChange('avatar', option)}
            options={avatars}
            isSearchable
            placeholder="Search and select Avatar"
          />
        </Form.Group>
        <Form.Group controlId="difficulty">
          <Form.Label>Difficulty</Form.Label>
          <Form.Control
            as="select"
            name="difficulty"
            value={interview.difficulty}
            onChange={(e) => handleChange('difficulty', { value: parseInt(e.target.value) })}
            required
          >
            <option value={1}>Normal</option>
            <option value={2}>Hard</option>
            <option value={3}>Very Hard</option>
          </Form.Control>
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Update Interview
        </Button>
      </Form>
    </div>
  );
};

export default EditInterview;



// // src/components/Interviews/EditInterview.js
// import React, { useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
// import api from '../../api';


// const EditInterview = () => {
//   const { id } = useParams();
//   const [interview, setInterview] = useState({
//     candidate: 1,
//     job: 1,
//     avatar: 1,
//     difficulty: 1,
//     planid: 1, // Add planid to the state

//   });
//   const [candidates, setCandidates] = useState([]);
//   const [jobs, setJobs] = useState([]);
//   const [avatars, setAvatars] = useState([]);
//   const [interviewPlans, setInterviewPlans] = useState([]); // Add state for interview plans
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [interviewResponse, candidatesResponse, jobsResponse, avatarsResponse, plansResponse] = await Promise.all([
//           api.get(`interviews/${id}/`),
//           api.get('candidates/'),
//           api.get('jobs/'),
//           api.get('avatars/'),
//           api.get('interviewplans/'), // Fetch interview plans

//         ]);

//         setCandidates(candidatesResponse.data.results);
//         setJobs(jobsResponse.data.results);
//         setAvatars(avatarsResponse.data.results);
//         setInterviewPlans(plansResponse.data.results); // Set interview plans


//         setInterview({
//           candidate: interviewResponse.data.candidate,
//           job: interviewResponse.data.job,
//           avatar: interviewResponse.data.avatar,
//           difficulty: interviewResponse.data.difficulty,
//           planid: interviewResponse.data.planid, // Set planid from response data

//         });

//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [id]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setInterview((prevInterview) => ({
//       ...prevInterview,
//       [name]: name === 'difficulty' ? parseInt(value) : parseInt(value),
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const interviewData = {
//       candidate: interview.candidate,
//       job: interview.job,
//       avatar: interview.avatar,
//       difficulty: interview.difficulty,
//       planid: interview.planid, // Include planid in the data

//     };

//     console.log("Interview Data to be saved:", interviewData); // Log data to check

//     try {
//       await api.put(`interviews/${id}/`, interviewData, {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       alert('Interview updated successfully');
//       navigate('/search-interview');
//     } catch (error) {
//       console.error('Error updating interview:', error.response ? error.response.data : error.message);
//       alert('Error updating interview');
//     }
//   };

//   return (
//     <Container className="mt-5">
//       <Row className="justify-content-md-center">
//         <Col md="8">
//           <Card>
//             <Card.Body>
//               <Card.Title className="text-center mb-4">Edit Interview</Card.Title>
//               <Form onSubmit={handleSubmit}>
//                 <Form.Group controlId="candidate" className="mb-3">
//                   <Form.Label>Candidate</Form.Label>
//                   <Form.Control
//                     as="select"
//                     name="candidate"
//                     value={interview.candidate}
//                     onChange={handleChange}
//                     required
//                   >
//                     <option value="">Select Candidate</option>
//                     {candidates.map((candidate) => (
//                       <option key={candidate.id} value={candidate.id}>
//                         {candidate.first_name} {candidate.last_name}
//                       </option>
//                     ))}
//                   </Form.Control>
//                 </Form.Group>
//                 <Form.Group controlId="job" className="mb-3">
//                   <Form.Label>Job</Form.Label>
//                   <Form.Control
//                     as="select"
//                     name="job"
//                     value={interview.job}
//                     onChange={handleChange}
//                     required
//                   >
//                     <option value="">Select Job</option>
//                     {jobs.map((job) => (
//                       <option key={job.id} value={job.id}>
//                         {job.title}
//                       </option>
//                     ))}
//                   </Form.Control>
//                 </Form.Group>
//                 <Form.Group controlId="planid" className="mb-3">
//                   <Form.Label>Interview Plan</Form.Label>
//                   <Form.Control
//                     as="select"
//                     name="planid"
//                     value={interview.planid}
//                     onChange={handleChange}
//                     required
//                   >
//                     <option value="">Select Interview Plan</option>
//                     {interviewPlans.map((plan) => (
//                       <option key={plan.id} value={plan.id}>
//                         {plan.plan_name}
//                       </option>
//                     ))}
//                   </Form.Control>
//                 </Form.Group>
       
//                 <Form.Group controlId="avatar" className="mb-3">
//                   <Form.Label>Avatar</Form.Label>
//                   <Form.Control
//                     as="select"
//                     name="avatar"
//                     value={interview.avatar}
//                     onChange={handleChange}
//                     required
//                   >
//                     <option value="">Select Avatar</option>
//                     {avatars.map((avatar) => (
//                       <option key={avatar.id} value={avatar.id}>
//                         {avatar.avatar_name}
//                       </option>
//                     ))}
//                   </Form.Control>
//                 </Form.Group>
//                 <Form.Group controlId="difficulty" className="mb-3">
//                   <Form.Label>Difficulty</Form.Label>
//                   <Form.Control
//                     as="select"
//                     name="difficulty"
//                     value={interview.difficulty}
//                     onChange={handleChange}
//                     required
//                   >
//                     <option value={1}>Normal</option>
//                     <option value={2}>Hard</option>
//                     <option value={3}>Very Hard</option>
//                   </Form.Control>
//                 </Form.Group>
//                 <Button variant="primary" type="submit" className="w-100 mt-3">
//                   Update Interview
//                 </Button>
//               </Form>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default EditInterview;
