// src/components/Modal.js
import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose, link }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(link).then(() => {
            alert('Link copied to clipboard!');
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Copy Link</h2>
                <input type="text" value={link} readOnly className="modal-input" />
                <button onClick={copyToClipboard}>Copy</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default Modal;
