import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import api from '../../api'; // Import the configured axios instance

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await api.post('/forgot-password/', { email: email });
          // const response = await api.post('/forgot-password/', { email: email });
          setMessage('Password reset link sent to your email.');
        } catch (error) {
            setMessage('Error sending password reset link.');
        }
    };

    return (
      <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="6">
          <Card>
            <Card.Body>
              <Card.Title className="text-center mb-4">Forgot Password</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                  Send Reset Link
                </Button>
              </Form>
              {message && (
                <div className="mt-3 text-center">
                  <p>{message}</p>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;