// src/components/Avatars/EditAvatar.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import api from '../../api';
import { getUserId, getCompanyId} from '../../utils/auth'; // Import the utility function

const EditAvatar = () => {
  const userId = getUserId();
  // const companyId = getCompanyId();

  const { id } = useParams();
  const [avatar, setAvatar] = useState({
    avatar_style: 1,
    avatar_face: 1,
    avatar_animationtype: 1,
    avatar_name: '',
    avatar_firstname: '',
    description: '',
    background: 1,
    camera_style: 1,
    iconset: '',
    buttonset: '',
    language: '',
    pitch: 'default',
    rate: 1, 
    volume: 100,
    expressive_style: 1,
    emotional_style: 1,
    created_by: userId,
    // usercompany:companyId,

});

  const navigate = useNavigate();
  const [languages, setLanguages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // State for preview (image and background)
  const [avatarImage, setAvatarImage] = useState('/avatar.png');  // Default avatar image from the program
  const [backgroundColor, setBackgroundColor] = useState('');  // Preview for background
  const [speechVoice, setSpeechVoice] = useState('');  // Language
  const [isChecked, setIsChecked] = useState(false);  // Checkbox state
  const [languageCountry, setLanguageCountry] = useState('');  // Checkbox state
  const [error, setError] = useState(null);  // Add state to store errors

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await api.get('api/languagevalues/');
        setLanguages(response.data);  // Set the fetched languages into state
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    fetchLanguages();
  }, []);  // Runs only once when component mounts

  const filteredLanguages = languages.filter(language =>
    language[2].toLowerCase().includes(searchTerm.toLowerCase()) ||
    language[5].toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const response = await api.get(`avatars/${id}/`);
        setAvatar(response.data);

        const colors = [
          '', 'navy', 'slategray', 'darkslateblue', 'midnightblue', 'teal', 'olive', 'beige', 'lavender', 'coral', 'firebrick', 
          'darkgreen', 'steelblue', 'saddlebrown', 'darkolivegreen', 'dimgray', 'lightslategray', 'indigo', 'mediumorchid', 
          'rebeccapurple', 'slateblue'
      ];
      
      setBackgroundColor(colors[response.data.background]);

      const response1 = await api.get(`api/languagevalues/?language_code=${response.data.language}`);

      setAvatarImage('/'+ response1.data[6]);  // Set avatarImage to 6th element (image path)
      setSpeechVoice(response1.data[4]);  // Set setSpeechVoice to 4th element (voice name)
      setLanguageCountry(response1.data[2]);



      } catch (error) {
        console.error('Error fetching avatar:', error);
      }
    };

    fetchAvatar();
  }, [id]);





  const handleChange = (e) => {
    const { name, value } = e.target;

    // Convert specific fields to integers
    const newValue = ['avatar_style', 'avatar_face', 'avatar_animationtype', 'background', 'camera_style', 'iconset', 'buttonset', 'language'].includes(name)
        ? parseInt(value, 10)
        : value;

    // Update background preview
    if (name === 'background') {
      const colors = [
        '', 'navy', 'slategray', 'darkslateblue', 'midnightblue', 'teal', 'olive', 'beige', 'lavender', 'coral', 'firebrick', 
        'darkgreen', 'steelblue', 'saddlebrown', 'darkolivegreen', 'dimgray', 'lightslategray', 'indigo', 'mediumorchid', 
        'rebeccapurple', 'slateblue'
    ];

        setBackgroundColor(colors[newValue]);
      }

    setAvatar((prevAvatar) => ({
        ...prevAvatar,
        [name]: newValue,
    }));
};



const languageChange = (e) => {
  const selectedLanguageIndex = parseInt(e.target.value, 10); // Get selected language index
  const selectedLanguage = languages.find(language => language[0] === selectedLanguageIndex); // Find matching row in languages array

  if (selectedLanguage) {
      setAvatar((prevAvatar) => ({
          ...prevAvatar,
          language: selectedLanguageIndex, // Update the language in the avatar state
      }));
      setAvatarImage('/'+ selectedLanguage[6]);  // Set avatarImage to 6th element (image path)
      setSpeechVoice(selectedLanguage[4]);  // Set setSpeechVoice to 4th element (voice name)
      setLanguageCountry(selectedLanguage[2]);
    } else {
      console.error("Language not found in array");
  }
};

const handleCheckboxChange = (e) => {
   setIsChecked(e.target.checked);  // Toggle checkbox
};


const handleSpeak = async () => {
  if (!speechVoice) {
      alert("Please select a language first.");
      return;
  }

  try {
      const subscriptionKey = '49cfae0357764d2b86ff759b7f2eeeb9';  // Replace with your Azure subscription key
      const serviceRegion = 'centralus';              // Replace with your Azure service region
      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
      speechConfig.speechSynthesisVoiceName = speechVoice;  // Set the TTS voice from speechVoice

      const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
      const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

      let text1 = "Hello, how has been your day so far?";
      

      if (isChecked) {
          // Perform translation if the checkbox is checked
          text1 = await fetchTranslatedText(text1, languageCountry);  // Assuming 'es-MX' for Mexican Spanish
          // setNewText(translation);  // Set newText to the translated text
      } 

      synthesizer.speakTextAsync(
          text1,
          result => {
              if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                  // console.log("Speech synthesis completed.");
                  setError(null);  // Clear any previous errors on success
              } else {
                  console.error(`Speech synthesis failed: ${result.errorDetails}`);
                  setError(`Speech synthesis failed: ${result.errorDetails}`);  // Set the error message

              }
          },
          error => {
              console.error(`Error: ${error}`);
              setError(`Error during synthesis: ${error}`);  // Set the error message

          }
      );
  } catch (error) {
      console.error("Error initializing speech synthesizer:", error);
      setError(`Error initializing speech synthesizer: ${error.message}`);  // Set the error message

  }
};

const fetchTranslatedText = async (text, languageCode) => {
  try {
      const response = await api.post('/api/translate/', {
          text: text,
          language_code: languageCode,
      });
      


      if (response.data.translated_text) {
      // console.log(response.data.translated_text)
      
          return response.data.translated_text;
      } else {
          console.error("Translation API failed:", response.data);
          return text;  // Return the original text if translation fails
      }
  } catch (error) {
      console.error("Error during translation request:", error);
      return text;  // Return the original text in case of an error
  }
};



  const handleSubmit = async (e) => {
    e.preventDefault();
    const avatarData = {
      ...avatar,
      created_by: userId, // Add the user ID to the avatar data
      // usercompany:companyId,

    };

    try {
      await api.put(`avatars/${id}/`, avatarData);
      alert('Avatar updated successfully');
      navigate('/search-avatars');
    } catch (error) {
      console.error('Error updating avatar:', error);
      alert('Error updating avatar');
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="8">
          <Card>
            <Card.Body>
              <Card.Title className="text-center mb-4">Edit Avatar</Card.Title>
              <Form onSubmit={handleSubmit}>
                {/* Avatar Name */}
                <Form.Group controlId="avatar_name" className="mb-3">
                  <Form.Label>Avatar Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="avatar_name"
                    value={avatar.avatar_name || ''}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="avatar_firstname" className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="avatar_firstname"
                    value={avatar.avatar_firstname || ''}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={avatar.description || ''}
                    onChange={handleChange}
                    rows={2}
                  />
                </Form.Group>

                {/* Avatar Style Dropdown (saved as integer) */}
                <Form.Group controlId="avatar_style" className="mb-3">
                  <Form.Label>Avatar Style</Form.Label>
                  <Form.Control as="select" name="avatar_style" value={avatar.avatar_style} onChange={handleChange} required disabled>
                    <option value="">Select Style</option>
                    <option value={1}>Image</option>
                  </Form.Control>
                </Form.Group>


                {/* Language Dropdown */}
                <Form.Group controlId="language" className="mb-3">
                  <Form.Label>Language and Gender</Form.Label>
                  <Form.Control as="select" name="language" value={avatar.language} onChange={languageChange} required>
                    <option value="">Select Language</option>
                    {filteredLanguages.map((language, index) => (
                      <option key={index} value={language[0]}>
                        {`${language[2]}, ${language[3]}, ${language[6]}`}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* Animation Type Dropdown */}
                <Form.Group controlId="avatar_animationtype" className="mb-3">
                  <Form.Label>Animation Type</Form.Label>
                  <Form.Control
                    as="select"
                    name="avatar_animationtype"
                    value={avatar.avatar_animationtype || 1}
                    onChange={handleChange}
                    required
                    disabled
                  >
                    <option value="">Select Animation</option>
                    <option value={1}>Default</option>
                  </Form.Control>
                </Form.Group>

                {/* Background Dropdown */}
                {/* Background Dropdown */}
                <Form.Group controlId="background" className="mb-3">
                  <Form.Label>Background</Form.Label>
                  <Form.Control as="select" name="background" value={avatar.background} onChange={handleChange} required>
                    <option value="">Select Background</option>
                    <option value={1}>Navy (Default)</option>
                    <option value={2}>SlateGray</option>
                    <option value={3}>DarkSlateBlue</option>
                    <option value={4}>MidnightBlue</option>
                    <option value={5}>Teal</option>
                    <option value={6}>Olive</option>
                    <option value={7}>Beige</option>
                    <option value={8}>Lavender</option>
                    <option value={9}>Coral</option>
                    <option value={10}>FireBrick</option>
                    <option value={11}>DarkGreen</option>
                    <option value={12}>SteelBlue</option>
                    <option value={13}>SaddleBrown</option>
                    <option value={14}>DarkOliveGreen</option>
                    <option value={15}>DimGray</option>
                    <option value={16}>LightSlateGray</option>
                    <option value={17}>Indigo</option>
                    <option value={18}>MediumOrchid</option>
                    <option value={19}>RebeccaPurple</option>
                    <option value={20}>SlateBlue</option>
                  </Form.Control>
                </Form.Group>


                {/* Camera Style Dropdown (saved as integer) */}
                <Form.Group controlId="camera_style" className="mb-3">
                  <Form.Label>Camera Style</Form.Label>
                  <Form.Control as="select" name="camera_style" value={avatar.camera_style || 1} onChange={handleChange} required disabled>
                    <option value="">Select Camera Style</option>
                    <option value={1}>Close-up</option>
                    <option value={2}>Medium</option>
                    <option value={3}>Wide</option>
                  </Form.Control>
                </Form.Group>

                {/* Iconset Dropdown (saved as integer) */}
                <Form.Group controlId="iconset" className="mb-3">
                  <Form.Label>Iconset</Form.Label>
                  <Form.Control as="select" name="iconset" value={avatar.iconset || 1} onChange={handleChange} required disabled>
                    <option value="">Select Iconset</option>
                    <option value={1}>Default</option>
                    <option value={2}>Set 1</option>
                    <option value={3}>Set 2</option>
                  </Form.Control>
                </Form.Group>

                {/* Buttonset Dropdown (saved as integer) */}
                <Form.Group controlId="buttonset" className="mb-3">
                  <Form.Label>Buttonset</Form.Label>
                  <Form.Control as="select" name="buttonset" value={avatar.buttonset || 1} onChange={handleChange} required disabled>
                    <option value="">Select Buttonset</option>
                    <option value={1}>Default</option>
                    <option value={2}>Set 1</option>
                  </Form.Control>
                </Form.Group>


                {/* Pitch (Radio Buttons) */}
                <Form.Group controlId="pitch" className="mb-3">
                  <Form.Label>Voice Pitch</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Default"
                      type="radio"
                      name="pitch"
                      value="default"
                      checked={avatar.pitch === "default"}
                      onChange={handleChange}
                      disabled
                    />
                    <Form.Check
                      inline
                      label="High"
                      type="radio"
                      name="pitch"
                      value="high"
                      checked={avatar.pitch === "high"}
                      onChange={handleChange}
                      disabled
                    />
                    <Form.Check
                      inline
                      label="Low"
                      type="radio"
                      name="pitch"
                      value="low"
                      checked={avatar.pitch === "low"}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                </Form.Group>

                {/* Rate (Range Slider with default value of 1) */}
                <Form.Group controlId="rate" className="mb-3">
                  <Form.Label>Speech Rate</Form.Label>
                  <Form.Control
                    type="range"
                    name="rate"
                    min="0.5"
                    max="2.0"
                    step="0.1"
                    value={avatar.rate}
                    onChange={handleChange}
                    disabled
                  />
                  <Form.Text className="text-muted">Current Rate: {avatar.rate || 1}</Form.Text>
                </Form.Group>

                {/* Volume (Range Slider) */}
                <Form.Group controlId="volume" className="mb-3">
                  <Form.Label>Voice Volume</Form.Label>
                  <Form.Control
                    type="range"
                    name="volume"
                    min="0"
                    max="100"
                    step="1"
                    value={avatar.volume}
                    onChange={handleChange}
                    disabled
                  />
                  <Form.Text className="text-muted">Current Volume: {avatar.volume || 100}</Form.Text>
                </Form.Group>

                {/* Expressive Style Dropdown */}
                <Form.Group controlId="expressive_style" className="mb-3">
                  <Form.Label>Expressive Style</Form.Label>
                  <Form.Control
                    as="select"
                    name="expressive_style"
                    value={avatar.expressive_style || 1}
                    onChange={handleChange}
                    disabled
                  >
                    <option value="default">Default Expressive Style</option>
                  </Form.Control>
                </Form.Group>

                {/* Emotional Style Dropdown */}
                <Form.Group controlId="emotional_style" className="mb-3">
                  <Form.Label>Emotional Style</Form.Label>
                  <Form.Control
                    as="select"
                    name="emotional_style"
                    value={avatar.emotional_style || 1}
                    onChange={handleChange}
                    disabled
                  >
                    <option value="default">Default Emotional Style</option>
                  </Form.Control>
                </Form.Group>

                {/* Submit Button */}
                <Button variant="primary" type="submit" className="w-100 mt-3">
                  Update Avatar
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        {/* Avatar Preview Section */}
        <Col md="4">
          <Card>
            <Card.Body>
              <h5 className="text-center">Avatar Preview</h5>
              <div
                className="avatar-preview"
                style={{
                  width: '100%',
                  height: '300px',
                  backgroundColor: backgroundColor,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  src={avatarImage}
                  alt="Avatar Preview"
                  style={{ width: '60%' }}
                />
              </div>


              <Form.Group controlId="enableTranslate" className="mt-3">
                <Form.Check
                  type="checkbox"
                  label="Translate to selected language"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>

              {/* Speak Button */}
              <Button variant="primary" className="w-100 mt-3" onClick={handleSpeak}>
                Speak
              </Button>
              {error && <p style={{ color: 'red' }}>{error}</p>}  {/* Conditionally render the error message */}

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};


export default EditAvatar;
