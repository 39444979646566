import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Form, InputGroup, Pagination } from 'react-bootstrap';
import api from '../../api';
import moment from 'moment'; // Import moment.js for date formatting
import { getUserId, getCompanyId} from '../../utils/auth'; // Import the utility function


const SearchJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchJobs();
  }, [currentPage]);

  const fetchJobs = async () => {
    try {
      // const response = await api.get(`api/jobs/?page=${currentPage}&search=${searchTerm}&ordering=-id`);
      const response = await api.get('api/jobs/', {
        params: {
          page: currentPage,
          search: searchTerm,
          ordering: '-id',
          usercompany: getCompanyId(),  // Include usercompany as a parameter
        },
      });

      setJobs(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10)); // Assuming page size is 10
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setCurrentPage(1); // Reset to first page for new search
    fetchJobs();
  };

  const handleEdit = (id) => {
    navigate(`/edit-job/${id}`);
  };

  const handleJobLink = (id) => {
    console.log("Job ID", id);
    navigate(`/create-joblink/${id}`);
  };
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  return (
    <div className="container mt-5">
    <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Search Jobs</h2>

        {/* Create Interview Button */}
        <Button
          variant="success"
          onClick={() => navigate('/add-job')}
          style={{ minWidth: '100px' }}
        >
          <i className="bi bi-plus-lg me-2"></i> {/* Bootstrap icon with right margin */}
          Job
        </Button>
      </div> 
      <Form onSubmit={handleSearch}>
        <InputGroup className="mb-4">
          <Form.Control
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by title or skills"
          />
          <Button variant="primary" type="submit">
            <i className="bi bi-search me-2"></i> Search
          </Button>
        </InputGroup>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Location</th>
            <th>Skills</th>
            <th>Duration</th>
            <th>Rate</th>
            <th>Company</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job) => (
            <tr key={job.id}>
              <td>{job.title}</td>
              <td>{job.location}</td>
              <td>{job.skills}</td>
              <td>{job.duration}</td>
              <td>{job.rate}</td>
              <td>{job.company}</td>
              <td>{job.posted_by_first_name && job.posted_by_last_name ? `${job.posted_by_first_name} ${job.posted_by_last_name}` : 'N/A'}</td>
              <td>{moment(job.posted_date).format('MM/DD/YY HH:mm')}</td>
              <td>
                <Button size="sm" variant="primary" onClick={() => handleEdit(job.id)}>
                  Edit
                </Button>
                <Button size="sm" variant="info" onClick={() => handleJobLink(job.id)}>
                  Job Link
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Pagination */}
      {totalPages > 1 && (
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

          {/* Logic for showing limited page numbers */}
          {currentPage > 2 && <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>}
          {currentPage > 3 && <Pagination.Ellipsis />}

          {/* Display a range of pages around the current page */}
          {Array.from({ length: totalPages }, (_, i) => i + 1)
            .filter((page) => page >= currentPage - 1 && page <= currentPage + 1)
            .map((page) => (
              <Pagination.Item key={page} active={page === currentPage} onClick={() => handlePageChange(page)}>
                {page}
              </Pagination.Item>
            ))}

          {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
          {currentPage < totalPages - 1 && (
            <Pagination.Item onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>
          )}

          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      )}
    </div>
  );
};

export default SearchJobs;
