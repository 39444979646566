// src/components/Avatars/SearchAvatars.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Form, InputGroup } from 'react-bootstrap';
import api from '../../api';

import { getUserId, getCompanyId } from '../../utils/auth'; // Import the utility function




const avatarStyles = {
  1: 'Image',
  // Add other styles as needed
};


const animationTypes = {
  1: 'Default',
  // Add other animation types as needed
};

const backgrounds = [
  '',
  'navy',
  'slategray',
  'darkslateblue',
  'midnightblue',
  'teal',
  'olive',
  'beige',
  'lavender',
  'coral',
  'firebrick',
  'darkgreen',
  'steelblue',
  'saddlebrown',
  'darkolivegreen',
  'dimgray',
  'lightslategray',
  'indigo',
  'mediumorchid',
  'rebeccapurple',
  'slateblue'
];

const cameraStyles = {
  1: 'Close-up',
  2: 'Medium',
  3: 'Wide',
};

const iconsets = {
  1: 'Default',
  2: 'Set 1',
  3: 'Set 2',
};

const buttonsets = {
  1: 'Default',
  2: 'Set 1',
};




const SearchAvatars = () => {
  const [avatars, setAvatars] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [languageArray, setLanguageArray] = useState([]); // New state to hold language array from the backend

  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        // const response = await api.get('avatars/');
        const response = await api.get(`avatars/?usercompany=${getCompanyId()}`);

        setAvatars(response.data.results);
      } catch (error) {
        console.error('Error fetching avatars:', error);
      }
    };


    const fetchLanguages = async () => {
      try {
        const response = await api.get('api/languagevalues/');  // Fetch the language array from the backend
        setLanguageArray(response.data);
      } catch (error) {
        console.error('Error fetching language array:', error);
      }
    };

    fetchLanguages();
    fetchAvatars();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const filteredAvatars = avatars.filter((avatar) =>
      avatar.avatar_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setAvatars(filteredAvatars);
  };

  const handleEdit = (id) => {
    navigate(`/edit-avatar/${id}`);
  };

  const getLanguageName = (languageCode) => {
    const language = languageArray.find(
      (lang) => lang[0] === languageCode  // Check if 0th element matches languageCode
    );
    return language ? language[2] : '';  // Return 2nd element if found, otherwise empty string
  };

  const getVoiceName = (languageCode) => {
    const language = languageArray.find(
      (lang) => lang[0] === languageCode  // Check if 0th element matches languageCode
    );
    return language ? language[4] : '';  // Return 2nd element if found, otherwise empty string
  };

  const getAvatarImage = (languageCode) => {
    const language = languageArray.find(
      (lang) => lang[0] === languageCode  // Check if 0th element matches languageCode
    );
    return language ? language[6] : '';  // Return 2nd element if found, otherwise empty string
  };

  const getAvatarGender = (languageCode) => {
    const language = languageArray.find(
      (lang) => lang[0] === languageCode  // Check if 0th element matches languageCode
    );
    return language ? language[5] : '';  // Return 2nd element if found, otherwise empty string
  };

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Search Avatars</h2>

        {/* Create Interview Button */}
        <Button
          variant="success"
          onClick={() => navigate('/add-avatar')}
          style={{ minWidth: '100px' }}
        >
          <i className="bi bi-plus-lg me-2"></i> {/* Bootstrap icon with right margin */}
          Avatar
        </Button>
      </div>            
      <Form onSubmit={handleSearch}>
        <InputGroup className="mb-4">
          <Form.Control
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by avatar name"
          />
          <Button variant="primary" type="submit">
            <i className="bi bi-search me-2"></i> Search
          </Button>
        </InputGroup>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Avatar Name</th>
            <th>Avatar First Name</th>
            <th>Description</th>
            <th>Language</th>
            <th>Voice</th>
            <th>Avatar Style</th>
            <th>Avatar Face</th>
            <th>Background</th>
            <th>Gender</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {avatars.map((avatar) => (
            <tr key={avatar.id}>
              <td>{avatar.avatar_name}</td>
              <td>{avatar.avatar_firstname}</td>
              <td>{avatar.description}</td>
              <td>{getLanguageName(avatar.language)}</td>
              <td>{getVoiceName(avatar.language)}</td>
              <td>{avatarStyles[avatar.avatar_style]}</td>
              <td>{getAvatarImage(avatar.language)}</td>
              <td>{backgrounds[avatar.background]}</td>
              <td>{getAvatarGender(avatar.language)}</td>
              <td>
                <Button size="sm" variant="primary" onClick={() => handleEdit(avatar.id)}>
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SearchAvatars;
