// src/components/Candidates/CandidateDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import api from '../../api';

const CandidateDetails = () => {
  const { id } = useParams();
  console.log(id);

  const [candidate, setCandidate] = useState(null);
  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const response = await api.get(`candidates/${id}/`); // Use the id dynamically

        setCandidate(response.data);
      } catch (error) {
        console.error('Error fetching candidate:', error);
      }
    };

    fetchCandidate();
  }, [id]);

  if (!candidate) return <div>Loading...</div>;

  return (
    <div className="container mt-5">
      <h2>Candidate Details</h2>
      <p>First Name: {candidate.first_name}</p>
      <p>Last Name: {candidate.last_name}</p>
      <p>Email: {candidate.email}</p>
      <p>Skills: {candidate.skills}</p>
      <p>Phone: {candidate.phone}</p>
      <p>Phone2: {candidate.phone2}</p>
      <p>Location: {candidate.location}</p>
      <p>LinkedIn Profile: {candidate.linkedin_profile}</p>
      <p>Created At: {new Date(candidate.created_at).toLocaleString()}</p>
      <br></br>
      <h6>Notes</h6>
      <textarea 
        value={candidate.notes} 
        readOnly 
        rows={6} 
        style={{ 
          width: '100%', 
          whiteSpace: 'pre-wrap', 
          border: 'none', 
          resize: 'none', 
          outline: 'none'
        }} 
      />
      <br></br>
      <h2>Resume</h2>
      <textarea 
        value={candidate.resume} 
        readOnly 
        rows={20} 
        style={{ 
          width: '100%', 
          whiteSpace: 'pre-wrap', 
          border: 'none', 
          resize: 'none', 
          outline: 'none'
        }} 
      />
      </div>
  );
};

export default CandidateDetails;
