import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import { Form, Button, Container, Row, Col, Card, Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ApplyJob = () => {
  const { link } = useParams();  // Extract the link from the URL
  const [jobData, setJobData] = useState(null);  // State to store job data
  const [candidate, setCandidate] = useState({
    first_name: '',
    last_name: '',
    email: '',
    skills: '',
    phone: '',
    phone2: '',
    location: '',
    linkedin_profile: '',
    resume: '',
    notes: '',
  });
  const [error, setError] = useState('');
  const [userId, setUserId] = useState(0); // State to store user_id from API response
  const [interview_plan_id, setInterview_plan_id] = useState(null); // State to store interview_plan_id from API response
  const [difficulty, setDifficulty] = useState(null); // State to store difficulty from API response
  const [avatar_id, setAvatar_id] = useState(null); // State to store avatar_id from API response
  const [job_id, setJob_id] = useState(null); // State to store avatar_id from API response
  const [userCompany, setUserCompany] = useState(0); // State to store avatar_id from API response


  const navigate = useNavigate();


  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await api.get(`applyjob-with-url/${link}/`);
        if (response.data.success) {
          // console.log(response.data)
          setJobData(response.data);
          setUserId(response.data.user_id); // Set user_id from API response
          setInterview_plan_id(response.data.interview_plan_id); // Set user_id from API response
          setDifficulty(response.data.difficulty); // Set user_id from API response
          setAvatar_id(response.data.avatar_id); // Set user_id from API response
          setJob_id(response.data.job.id); // Set user_id from API response
          setUserCompany(response.data.usercompany); 

          // console.log("response.data.job.id", response.data.job.id);

        } else {
          setError('Invalid link.');
        }
      } catch (error) {
        setError('An error occurred while fetching job details.');
      }
    };

    fetchJobDetails();
  }, [link]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCandidate((prevCandidate) => ({
      ...prevCandidate,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const candidateData = {
        ...candidate,
        user_id: userId,  // Use user_id from API response
        link:link,             // Include the job link
        job_id:job_id,
        interview_plan_id: interview_plan_id,
        difficulty:difficulty,
        avatar_id:avatar_id,
        usercompany: userCompany,

      };
      const response = await api.post('saveurlcandidate/', candidateData);
      if (response.data.success) {
        alert('Application submitted successfully!');
        navigate('/confirmation');  // Redirect to the confirmation page

      } else {
        alert('Failed to submit application.');
      }
    } catch (error) {
      alert('Error submitting application. ' + (error.response?.data?.error || error.message));
    }  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!jobData) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="8">
          <Card>
            <Card.Body>
              <Card.Title className="text-center mb-4">Apply for {jobData.job.title}</Card.Title>
              <p className="text-center">
              You are invited to apply for this position. Upon successful submission of your application, you will receive an immediate email invitation to complete a video interview which you make take at your earliest convenience.</p>

              {/* Position Details Accordion */}
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Position Details</Accordion.Header>
                  <Accordion.Body>
                    <p><strong>Company:</strong> {jobData.job.company}</p>
                    <p><strong>Location:</strong> {jobData.job.location}</p>
                    <p><strong>Skills:</strong> {jobData.job.skills || 'N/A'}</p>
                    <p><strong>Duration:</strong> {jobData.job.duration || 'N/A'}</p>
                    <p><strong>Job Description:</strong></p>
                    <p>{jobData.job.description || 'No description available.'}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Form onSubmit={handleSubmit} className="mt-4">
                <Form.Group controlId="firstName" className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={candidate.first_name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="lastName" className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={candidate.last_name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={candidate.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="phone" className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={candidate.phone}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="phone2" className="mb-3">
                  <Form.Label>Alternate Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone2"
                    value={candidate.phone2}
                    onChange={handleChange}
                    placeholder="Optional"
                  />
                </Form.Group>
                <Form.Group controlId="location" className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={candidate.location}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="skills" className="mb-3">
                  <Form.Label>Skills</Form.Label>
                  <Form.Control
                    type="text"
                    name="skills"
                    value={candidate.skills}
                    onChange={handleChange}
                    placeholder="Enter skills (e.g., JavaScript, Python)"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="linkedin_profile" className="mb-3">
                  <Form.Label>LinkedIn Profile</Form.Label>
                  <Form.Control
                    type="url"
                    name="linkedin_profile"
                    value={candidate.linkedin_profile}
                    onChange={handleChange}
                    placeholder="LinkedIn profile URL"
                  />
                </Form.Group>
                <Form.Group controlId="notes" className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="notes"
                    value={candidate.notes}
                    onChange={handleChange}
                    rows={6}
                    />
                </Form.Group>
                <Form.Group controlId="resume" className="mb-3">
                  <Form.Label>Resume</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="resume"
                    value={candidate.resume}
                    onChange={handleChange}
                    required
                    rows={5}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 mt-3">
                  Apply for Job
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ApplyJob;
