// src/components/Candidates/EditCandidate.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import api from '../../api';
import { getUserId, getCompanyId} from '../../utils/auth'; // Import the utility function


const EditCandidate = () => {
  const userId = getUserId();
  const companyId = getCompanyId();

  const { id } = useParams();
  const [candidate, setCandidate] = useState({
    first_name: '',
    last_name: '',
    email: '',
    skills: '',
    phone: '',
    phone2: '',
    location: '',
    //profile_picture: null,
    linkedin_profile: '',
    resume: '',
    notes: '',
    created_by:userId,
    ip_address:"192.168.1.1",
    usercompany:companyId,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const response = await api.get(`candidates/${id}/`);
        console.log(response);
        setCandidate(response.data);
      } catch (error) {
        console.error('Error fetching candidate:', error);
      }
    };

    fetchCandidate();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCandidate((prevCandidate) => ({
      ...prevCandidate,
      [name]: value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in candidate) {
      // if (key !== 'profile_picture') {
      //   formData.append(key, candidate[key]);
      // } else if (candidate.profile_picture) {
      //   formData.append('profile_picture', candidate.profile_picture);
      // }
      if (key !== 'created_by') { // Exclude 'created_by' field
        formData.append(key, candidate[key]);
      }
    }

    formData.append('created_by', userId); // Append the user ID
    formData.append('usercompany', companyId); // Append the user ID


    try {
      await api.put(`candidates/${id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Candidate updated successfully');
      navigate('/search-candidates');
    } catch (error) {
      console.error('Error updating candidate:', error);
      alert('Error updating candidate');
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="8">
          <Card>
            <Card.Body>
              <Card.Title className="text-center mb-4">Edit Candidate</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="firstName" className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={candidate.first_name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="lastName" className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={candidate.last_name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={candidate.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="phone" className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={candidate.phone}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="phone2" className="mb-3">
                  <Form.Label>Alternate Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone2"
                    value={candidate.phone2}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="location" className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={candidate.location}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="skills" className="mb-3">
                  <Form.Label>Skills</Form.Label>
                  <Form.Control
                    type="text"
                    name="skills"
                    value={candidate.skills}
                    onChange={handleChange}
                    placeholder="Enter skills as comma-separated values (e.g., JavaScript,React,Node.js)"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="linkedinProfile" className="mb-3">
                  <Form.Label>LinkedIn Profile</Form.Label>
                  <Form.Control
                    type="url"
                    name="linkedin_profile"
                    value={candidate.linkedin_profile}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="notes" className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="notes"
                    value={candidate.notes}
                    onChange={handleChange}
                    rows={6}
                    />
                </Form.Group>
                <Form.Group controlId="resume" className="mb-3">
                  <Form.Label>Resume</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="resume"
                    value={candidate.resume}
                    onChange={handleChange}
                    required
                    rows={30}
                  />
                </Form.Group>
                {/* <Form.Group controlId="profilePicture" className="mb-3">
                  <Form.Label>Profile Picture</Form.Label>
                  <Form.Control
                    type="file"
                    name="profile_picture"
                    onChange={handleFileChange}
                  />
                </Form.Group> */}
                <Button variant="primary" type="submit" className="w-100 mt-3">
                  Update Candidate
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditCandidate;
