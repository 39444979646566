import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import moment from 'moment';
import './InterviewDetail.css';
// import './FloatingVideo.css';  // Custom styles for floating video

import { Table, Button, Card, Form, Container, Row, Col } from 'react-bootstrap';
import Hls from 'hls.js';  // Import Hls.js for HLS streaming
import { Rnd } from 'react-rnd';  // For draggable and resizable components


const PublicInterviewDetail = () => {
  const { linkId } = useParams();  // Extract the linkId from the URL
  const [passcode, setPasscode] = useState('');  // State to store the passcode entered by the user
  const [isVerified, setIsVerified] = useState(false);  // State to track whether the passcode has been verified
  const [interview, setInterview] = useState(null);  // State to store interview details after verification
  const [isVideoFloating, setIsVideoFloating] = useState(false);
  const videoRef = useRef(null);  // Reference to the video element
  const hlsRef = useRef(null);    // Reference to Hls.js instance
  const [videoUrl, setVideoUrl] = useState('');  // URL of the video to play

  // *************************************************************
const [xPosition, setXPosition] = useState(0);
const [yPosition, setYPosition] = useState(0);
// **************************************************************

  // Function to verify the passcode and get data if passcode url verify
  const verifyPasscode = async (e) => {

    e.preventDefault();  // Prevent default form submission behavior

    if (!passcode) {
      alert("Please enter the passcode.");  // Alert if passcode is not entered
      return;
    }

    const browserInfo = navigator.userAgent;

      try {
        const data = {
          linkId: linkId, 
          passcode: passcode,
          browser_info: browserInfo
        };        

      const response = await api.post(`verify_interview_link/`, data);  // API call to verify passcode

      if (response.data.success) {

        setIsVerified(true);  // Set verification status to true if passcode is correct
        setInterview(response.data);  // Store interview details in state
        console.log(response.data)

      } else {
        alert('Invalid link/passcode. Please try again.');  // Alert user if passcode is incorrect
      }
    } catch (error) {
      console.error('Error verifying link/passcode:', error);
      alert('An error occurred while verifying the link/passcode.');  // Alert user in case of API call error
    }
  };

// ******************************************************

const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('safari') && !userAgent.includes('chrome');
};

const initializeVideo = (url) => {
  setIsVideoFloating(true);  // Show the floating video player

  if (isSafari()) {
    // Safari supports HLS natively
    if (videoRef.current) {
      videoRef.current.src = url;
      videoRef.current.play();  // Auto-play for Safari
    }
  } else if (Hls.isSupported()) {
    // Non-Safari browsers: use Hls.js for HLS streaming
    if (hlsRef.current) {
      hlsRef.current.destroy();  // Clean up any previous HLS instance
    }
    const hls = new Hls();
    hls.loadSource(url);  // Load the HLS URL
    hls.attachMedia(videoRef.current);  // Attach HLS to the video element
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      videoRef.current.play();  // Auto-play when manifest is parsed
    });
    hlsRef.current = hls;  // Store the Hls.js instance
  } else {
    // Fallback for unsupported browsers
    if (videoRef.current) {
      videoRef.current.src = url;
      videoRef.current.play();
    }
  }
};



// ******************************************************


const playVideo = (url) => {
  // setCurrentVideoUrl(url);  // Set the video URL
  setVideoUrl(url);  // Set the video URL when button is clicked

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  // Set x and y to place the video in the bottom-right corner of the browser
  const x = windowWidth - 700 + scrollX; // 20px from the right edge
  const y = windowHeight - 580 + scrollY; // 20px from the bottom edge

  setXPosition(x);
  setYPosition(y);


  initializeVideo(url);  // Initialize video for HLS or regular playback
};


useEffect(() => {
  // Reinitialize the video whenever the video URL changes
  if (videoUrl && videoRef.current) {
    initializeVideo(videoUrl);
  }
});


const closeVideo = () => {
  if (videoRef.current) {
    videoRef.current.pause();  // Pause the video
    videoRef.current.removeAttribute('src');  // Remove the video source
    videoRef.current.load();  // Reset the video element
  }

  if (hlsRef.current) {
    hlsRef.current.destroy();  // Destroy the Hls.js instance
    hlsRef.current = null;
  }

  setIsVideoFloating(false);  // Hide the floating video player
};

  if (!isVerified) {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <Row>
          <Col>
            <Card className="shadow-sm" style={{ maxWidth: '400px', margin: 'auto' }}>
              <Card.Body>
                <Card.Title className="text-center mb-4">Enter Passcode</Card.Title>
                <Card.Text className="text-center mb-4">
                  Please enter the passcode provided to view the interview.
                </Card.Text>
                <Form>
                  <Form.Group controlId="formPasscode">
                    <Form.Control
                      type="text"
                      value={passcode}
                      onChange={(e) => setPasscode(e.target.value)}
                      placeholder="Enter passcode"
                      className="mb-3"
                      required
                      autoFocus
                    />
                  </Form.Group>
                  <Button variant="primary" onClick={verifyPasscode}>
                    Verify
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="my-5">
      <Card className="shadow-sm">
        <Card.Header as="h2" className="text-center">Interview Details</Card.Header>
        <Card.Body>
          {interview && (
            <Row className="mb-6">
              <Col md={12}>
                <Card.Text>
                  <strong>Candidate:</strong> {interview.candidate_first_name} {interview.candidate_last_name}
                </Card.Text>
              </Col>
              <Col md={6}>
                <Card.Text>
                  <strong>Job Title:</strong> {interview.job_title}
                </Card.Text>
              </Col>
              <Col md={6}>
                <Card.Text>
                  <strong>Location:</strong> {interview.job_location}
                </Card.Text>
              </Col>
              <Col md={6}>
                <Card.Text>
                  <strong>Client:</strong> {interview.job_company}
                </Card.Text>
              </Col>
            </Row>
          )}
          <h5 className="mb-4">Responses</h5>
          <Table striped bordered hover responsive>
            <thead className="thead-dark">
              <tr>
                <th>Question Number</th>
                <th>Question and Response</th>
                <th>Analysis</th>
                <th>Points</th>
                <th>Question Start Time</th>
                <th>Video</th>
              </tr>
            </thead>
            <tbody>
              {interview.responses.map((response) => (
                <tr key={response.id}>
                  <td>{response.questionnumber}</td>
                  <td>
                    <strong>Question:</strong> {response.question_text}
                    <br />
                    <strong>Response:</strong> {response.response_text}
                  </td>
                  <td>{response.AIAnalysis}</td>
                  <td>{response.AIPoints}</td>
                  <td>{moment(response.questionstarttime).format('MM/DD/YY HH:mm:ss')}</td>
                  <td>
                  <Button
                      variant="primary"
                      size="sm"
                      onClick={() => playVideo(response.video_url)}  // Set the video URL when button is clicked
                      disabled={!response.video_url || response.video_url === ""}
                    >
                      Play Video
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {isVideoFloating && (
          <Rnd
          default={{
            width: 480,
            height: 360,
            x: xPosition,
            y: yPosition,
          }}
          dragHandleClassName="video-drag-handle"
          className="floating-video"
          style={{
            position: 'fixed',  // Ensure it stays fixed in the viewport
            zIndex: 1000,
          }}
        >

          <div className="video-container">
            <div className="video-header">
              <span className="video-drag-handle">Drag Me</span>
              <button className="close-button" onClick={closeVideo}>✖</button>
            </div>
            <video
              ref={videoRef}
              controls
              width="100%"
              height="100%"
            >
              {isSafari() ? (
                <source src={videoUrl} type="application/x-mpegURL" />
              ) : null}
            </video>
          </div>
        </Rnd>
          )}
    </Container>
  );
};

export default PublicInterviewDetail;

























// import React, { useState } from 'react';
// import { useParams } from 'react-router-dom';
// import api from '../../api';
// import moment from 'moment';
// import './InterviewDetail.css';
// import { Table, Button, Card, Form, Container, Row, Col } from 'react-bootstrap';


// const PublicInterviewDetail = () => {
//   const { linkId } = useParams();  // Extract the linkId from the URL
//   const [passcode, setPasscode] = useState('');  // State to store the passcode entered by the user
//   const [isVerified, setIsVerified] = useState(false);  // State to track whether the passcode has been verified
//   const [interview, setInterview] = useState(null);  // State to store interview details after verification
//   const [currentVideoUrl, setCurrentVideoUrl] = useState('');
//   const [isVideoFloating, setIsVideoFloating] = useState(false);


//   // Function to verify the passcode and get data if passcode url verify
//   const verifyPasscode = async (e) => {

//     e.preventDefault();  // Prevent default form submission behavior

//     if (!passcode) {
//       alert("Please enter the passcode.");  // Alert if passcode is not entered
//       return;
//     }

//     const browserInfo = navigator.userAgent;

//       try {
//         const data = {
//           linkId: linkId, 
//           passcode: passcode,
//           browser_info: browserInfo
//         };        

//       const response = await api.post(`verify_interview_link/`, data);  // API call to verify passcode

//       if (response.data.success) {

//         setIsVerified(true);  // Set verification status to true if passcode is correct
//         setInterview(response.data);  // Store interview details in state
//         console.log(response.data)

//       } else {
//         alert('Invalid link/passcode. Please try again.');  // Alert user if passcode is incorrect
//       }
//     } catch (error) {
//       console.error('Error verifying link/passcode:', error);
//       alert('An error occurred while verifying the link/passcode.');  // Alert user in case of API call error
//     }
//   };

//   const playVideo = (url) => {
//     setCurrentVideoUrl(url);
//     setIsVideoFloating(true); // Make the video float
//   };

//   const closeVideo = () => {
//     setCurrentVideoUrl('');
//     setIsVideoFloating(false);
//   };


//   if (!isVerified) {
//     return (
//       <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
//         <Row>
//           <Col>
//             <Card className="shadow-sm" style={{ maxWidth: '400px', margin: 'auto' }}>
//               <Card.Body>
//                 <Card.Title className="text-center mb-4">Enter Passcode</Card.Title>
//                 <Card.Text className="text-center mb-4">
//                   Please enter the passcode provided to view the interview.
//                 </Card.Text>
//                 <Form>
//                   <Form.Group controlId="formPasscode">
//                     <Form.Control
//                       type="text"
//                       value={passcode}
//                       onChange={(e) => setPasscode(e.target.value)}
//                       placeholder="Enter passcode"
//                       className="mb-3"
//                       required
//                       autoFocus
//                     />
//                   </Form.Group>
//                   <Button variant="primary" onClick={verifyPasscode}>
//                     Verify
//                   </Button>
//                 </Form>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>
//       </Container>
//     );
//   }

//   return (
//     <Container className="my-5">
//       <Card className="shadow-sm">
//         <Card.Header as="h2" className="text-center">Interview Details</Card.Header>
//         <Card.Body>
//           {interview && (
//             <Row className="mb-4">
//               <Col md={6}>
//                 <Card.Text>
//                   <strong>Candidate:</strong> {interview.candidate_first_name} {interview.candidate_last_name}
//                 </Card.Text>
//               </Col>
//               <Col md={6}>
//                 <Card.Text>
//                   <strong>Job Title:</strong> {interview.job_title}
//                 </Card.Text>
//               </Col>
//               <Col md={6}>
//                 <Card.Text>
//                   <strong>Location:</strong> {interview.job_location}
//                 </Card.Text>
//               </Col>
//               <Col md={6}>
//                 <Card.Text>
//                   <strong>Client:</strong> {interview.job_company}
//                 </Card.Text>
//               </Col>
//             </Row>
//           )}
//           <h5 className="mb-4">Responses</h5>
//           <Table striped bordered hover responsive>
//             <thead className="thead-dark">
//               <tr>
//                 <th>Question Number</th>
//                 <th>Question and Response</th>
//                 <th>Analysis</th>
//                 <th>Points</th>
//                 <th>Question Start Time</th>
//                 <th>Video</th>
//               </tr>
//             </thead>
//             <tbody>
//               {interview.responses.map((response) => (
//                 <tr key={response.id}>
//                   <td>{response.questionnumber}</td>
//                   <td>
//                     <strong>Question:</strong> {response.question_text}
//                     <br />
//                     <strong>Response:</strong> {response.response_text}
//                   </td>
//                   <td>{response.AIAnalysis}</td>
//                   <td>{response.AIPoints}</td>
//                   <td>{moment(response.questionstarttime).format('MM/DD/YY HH:mm:ss')}</td>
//                   <td>
//                     <Button variant="primary" size="sm" onClick={() => playVideo(response.video_url)}>
//                       Play Video
//                     </Button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </Card.Body>
//       </Card>

//       {isVideoFloating && (
//         <div className="floating-video">
//           <div className="video-container">
//             <button className="close-button" onClick={closeVideo}>✖</button>
//             <video key={currentVideoUrl} width="300" controls autoPlay>
//               <source src={currentVideoUrl} type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//           </div>
//         </div>
//       )}
//     </Container>
//   );
// };

// export default PublicInterviewDetail;


