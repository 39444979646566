import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import api from '../../api';
import { getUserId, getCompanyId } from '../../utils/auth';
import useDebounce from '../../hooks/useDebounce';

const CreateInterview = () => {
  const userId = getUserId();
  const companyId = getCompanyId();

  const [interview, setInterview] = useState({
    candidate: null,
    job: null,
    avatar: null,
    difficulty: 1,
    interviewPlan: null,
    created_by: userId,
    usercompany: companyId,
  });

  const [candidates, setCandidates] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [interviewPlans, setInterviewPlans] = useState([]);
  const [searchTerms, setSearchTerms] = useState({
    candidate: '',
    job: '',
    avatar: '',
    interviewPlan: '',
  });

  const debouncedCandidateSearch = useDebounce(searchTerms.candidate, 700);
  const debouncedJobSearch = useDebounce(searchTerms.job, 700);

  const navigate = useNavigate();

  // Fetch candidates based on debounced search term
  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await api.get('search/candidatesearch/', {
          params: { search: debouncedCandidateSearch },
        });
        setCandidates(response.data.map(candidate => ({
          value: candidate.id,
          label: candidate.label,
        })));
      } catch (error) {
        console.error('Error fetching candidates:', error);
      }
    };
    if (debouncedCandidateSearch) fetchCandidates();
  }, [debouncedCandidateSearch]);

  // Fetch jobs based on debounced search term
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await api.get('search/jobsearch/', {
          params: { search: debouncedJobSearch },
        });
        setJobs(response.data.map(job => ({
          value: job.id,
          label: job.label,
        })));
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };
    if (debouncedJobSearch) fetchJobs();
  }, [debouncedJobSearch]);

  // Fetch static data for avatars and interview plans
  useEffect(() => {
    const fetchStaticData = async () => {
      try {
        const [avatarResponse, interviewPlanResponse] = await Promise.all([
          api.get('avatars/'),
          api.get('interviewplans/'),
        ]);

        setAvatars(avatarResponse.data.results.map(avatar => ({
          value: avatar.id,
          label: avatar.avatar_name,
        })));

        setInterviewPlans(interviewPlanResponse.data.results.map(plan => ({
          value: plan.id,
          label: plan.plan_name,
        })));
      } catch (error) {
        console.error('Error fetching avatars or interview plans:', error);
      }
    };

    fetchStaticData();
  }, []);

  const handleSearchChange = (name, value) => {
    setSearchTerms(prevSearchTerms => ({
      ...prevSearchTerms,
      [name]: value,
    }));
  };

  const handleChange = (name, selectedOption) => {
    setInterview(prevInterview => ({
      ...prevInterview,
      [name]: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!interview.candidate || !interview.job) {
      alert("Please select both a candidate and a job.");
      return;
    }

    const interviewData = {
      candidate: interview.candidate,
      job: interview.job,
      avatar: interview.avatar,
      difficulty: interview.difficulty,
      planid: interview.interviewPlan,
      created_by: userId,
      usercompany: companyId,
    };

    try {
      await api.post('interviews/', interviewData, {
        headers: { 'Content-Type': 'application/json' },
      });
      alert('Interview created successfully');
      navigate('/search-interview');
    } catch (error) {
      console.error('Error creating interview:', error.response ? error.response.data : error.message);
      alert('Error creating interview');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Create Interview</h2>
      <Form onSubmit={handleSubmit} className="mt-4">
        <Form.Group controlId="candidate">
          <Form.Label>Candidate</Form.Label>
          <Select
            name="candidate"
            value={candidates.find(option => option.value === interview.candidate) || null}
            onInputChange={(value) => handleSearchChange('candidate', value)}
            onChange={(option) => handleChange('candidate', option)}
            options={candidates}
            isSearchable
            placeholder="Search and select Candidate"
          />
        </Form.Group>
        <Form.Group controlId="job">
          <Form.Label>Job</Form.Label>
          <Select
            name="job"
            value={jobs.find(option => option.value === interview.job) || null}
            onInputChange={(value) => handleSearchChange('job', value)}
            onChange={(option) => handleChange('job', option)}
            options={jobs}
            isSearchable
            placeholder="Search and select Job"
          />
        </Form.Group>
        <Form.Group controlId="interviewPlan">
          <Form.Label>Interview Plan</Form.Label>
          <Select
            name="interviewPlan"
            value={interviewPlans.find(option => option.value === interview.interviewPlan) || null}
            onChange={(option) => handleChange('interviewPlan', option)}
            options={interviewPlans}
            isSearchable
            placeholder="Search and select Interview Plan"
          />
        </Form.Group>
        <Form.Group controlId="avatar">
          <Form.Label>Avatar</Form.Label>
          <Select
            name="avatar"
            value={avatars.find(option => option.value === interview.avatar) || null}
            onChange={(option) => handleChange('avatar', option)}
            options={avatars}
            isSearchable
            placeholder="Search and select Avatar"
          />
        </Form.Group>
        <Form.Group controlId="difficulty">
          <Form.Label>Difficulty</Form.Label>
          <Form.Control as="select" name="difficulty" value={interview.difficulty} onChange={(e) => handleChange('difficulty', { value: parseInt(e.target.value) })} required>
            <option value={1}>Normal</option>
            <option value={2}>Hard</option>
            <option value={3}>Very Hard</option>
          </Form.Control>
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Create Interview
        </Button>
      </Form>
    </div>
  );
};

export default CreateInterview;




// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Form, Button } from 'react-bootstrap';
// import Select from 'react-select';
// import api from '../../api';
// import { getUserId, getCompanyId} from '../../utils/auth'; // Import the utility function

// const CreateInterview = () => {
//   const userId = getUserId();
//   const companyId = getCompanyId();

//   const [interview, setInterview] = useState({
//     candidate: null,
//     job: null,
//     avatar: null,
//     difficulty: 1,
//     interviewPlan: null,
//     created_by: userId,
//     usercompany: companyId,
//   });

//   const [candidates, setCandidates] = useState([]);
//   const [jobs, setJobs] = useState([]);
//   const [avatars, setAvatars] = useState([]);
//   const [interviewPlans, setInterviewPlans] = useState([]);

//   const [searchTerms, setSearchTerms] = useState({
//     candidate: '',
//     job: '',
//     avatar: '',
//     interviewPlan: '',
//   });

//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [candidateResponse, jobResponse, avatarResponse, interviewPlanResponse] = await Promise.all([
//           api.get('candidates/', {
//             params: { 
//               ordering: '-id',
//               search: searchTerms.candidate,
//             },
//           }),
//           api.get('jobs/', {
//             params: { 
//               ordering: '-id',
//               search: searchTerms.job,
//             },
//           }),
//           api.get('avatars/', {
//             params: { 
//               ordering: 'id',
//               search: searchTerms.avatar,
//             },
//           }),
//           api.get('interviewplans/', {
//             params: { 
//               ordering: 'id',
//               search: searchTerms.interviewPlan,
//             },
//           }),
//         ]);

//         setCandidates(candidateResponse.data.results.map(candidate => ({
//           value: candidate.id,
//           label: `${candidate.first_name} ${candidate.last_name}`,
//         })));

//         setJobs(jobResponse.data.results.map(job => ({
//           value: job.id,
//           label: job.title,
//         })));

//         setAvatars(avatarResponse.data.results.map(avatar => ({
//           value: avatar.id,
//           label: avatar.avatar_name,
//         })));

//         setInterviewPlans(interviewPlanResponse.data.results.map(plan => ({
//           value: plan.id,
//           label: plan.plan_name,
//         })));

//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [searchTerms]);

//   const handleSearchChange = (name, value) => {
//     setSearchTerms((prevSearchTerms) => ({
//       ...prevSearchTerms,
//       [name]: value,
//     }));
//   };

//   const handleChange = (name, selectedOption) => {
//     setInterview((prevInterview) => ({
//       ...prevInterview,
//       [name]: selectedOption ? selectedOption.value : null,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const interviewData = {
//       candidate: interview.candidate,
//       job: interview.job,
//       avatar: interview.avatar,
//       difficulty: interview.difficulty,
//       planid: interview.interviewPlan,
//       created_by: userId,
//       usercompany: companyId,
//     };

//     try {
//       await api.post('interviews/', interviewData, {
//         headers: { 'Content-Type': 'application/json' },
//       });
//       alert('Interview created successfully');
//       navigate('/search-interview');
//     } catch (error) {
//       console.error('Error creating interview:', error.response ? error.response.data : error.message);
//       alert('Error creating interview');
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h2>Create Interview</h2>
//       <Form onSubmit={handleSubmit} className="mt-4">
//         <Form.Group controlId="candidate">
//           <Form.Label>Candidate</Form.Label>
//           <Select
//             name="candidate"
//             value={candidates.find((option) => option.value === interview.candidate)}
//             onInputChange={(value) => handleSearchChange('candidate', value)}
//             onChange={(option) => handleChange('candidate', option)}
//             options={candidates}
//             isSearchable
//             placeholder="Search and select Candidate"
//           />
//         </Form.Group>
//         <Form.Group controlId="job">
//           <Form.Label>Job</Form.Label>
//           <Select
//             name="job"
//             value={jobs.find((option) => option.value === interview.job)}
//             onInputChange={(value) => handleSearchChange('job', value)}
//             onChange={(option) => handleChange('job', option)}
//             options={jobs}
//             isSearchable
//             placeholder="Search and select Job"
//           />
//         </Form.Group>
//         <Form.Group controlId="interviewPlan">
//           <Form.Label>Interview Plan</Form.Label>
//           <Select
//             name="interviewPlan"
//             value={interviewPlans.find((option) => option.value === interview.interviewPlan)}
//             onInputChange={(value) => handleSearchChange('interviewPlan', value)}
//             onChange={(option) => handleChange('interviewPlan', option)}
//             options={interviewPlans}
//             isSearchable
//             placeholder="Search and select Interview Plan"
//           />
//         </Form.Group>
//         <Form.Group controlId="avatar">
//           <Form.Label>Avatar</Form.Label>
//           <Select
//             name="avatar"
//             value={avatars.find((option) => option.value === interview.avatar)}
//             onInputChange={(value) => handleSearchChange('avatar', value)}
//             onChange={(option) => handleChange('avatar', option)}
//             options={avatars}
//             isSearchable
//             placeholder="Search and select Avatar"
//           />
//         </Form.Group>
//         <Form.Group controlId="difficulty">
//           <Form.Label>Difficulty</Form.Label>
//           <Form.Control as="select" name="difficulty" value={interview.difficulty} onChange={(e) => handleChange(e.target.name, { value: parseInt(e.target.value) })} required>
//             <option value={1}>Normal</option>
//             <option value={2}>Hard</option>
//             <option value={3}>Very Hard</option>
//           </Form.Control>
//         </Form.Group>
//         <Button variant="primary" type="submit" className="mt-3">
//           Create Interview
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default CreateInterview;



// import React, { useState, useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Form, Button } from 'react-bootstrap';
// import Select from 'react-select';
// import api from '../../api';
// import { getUserId, getCompanyId } from '../../utils/auth';
// import useDebounce from '../../hooks/useDebounce';

// const CreateInterview = () => {
//   const userId = getUserId();
//   const companyId = getCompanyId();

//   const [interview, setInterview] = useState({
//     candidate: null,
//     job: null,
//     avatar: null,
//     difficulty: 1,
//     interviewPlan: null,
//     created_by: userId,
//     usercompany: companyId,
//   });

//   const [candidates, setCandidates] = useState([]);
//   const [jobs, setJobs] = useState([]);
//   const [avatars, setAvatars] = useState([]);
//   const [interviewPlans, setInterviewPlans] = useState([]);

//   const [searchTerms, setSearchTerms] = useState({
//     candidate: '',
//     job: '',
//     avatar: '',
//     interviewPlan: '',
//   });

//   const navigate = useNavigate();

//   const debouncedCandidateSearch = useDebounce(searchTerms.candidate, 700);
//   const debouncedJobSearch = useDebounce(searchTerms.job, 700);
//   const debouncedAvatarSearch = useDebounce(searchTerms.avatar, 700);
//   const debouncedInterviewPlanSearch = useDebounce(searchTerms.interviewPlan, 700);

//   useEffect(() => {
//     const fetchCandidates = async () => {
//       try {
//         const response = await api.get('candidates/', { params: { ordering: '-id', search: debouncedCandidateSearch } });
//         setCandidates(response.data.results.map(candidate => ({
//           value: candidate.id,
//           label: `${candidate.first_name} ${candidate.last_name}`,
//         })));
//       } catch (error) {
//         console.error('Error fetching candidates:', error);
//       }
//     };
//     if (debouncedCandidateSearch) fetchCandidates();
//   }, [debouncedCandidateSearch]);

//   useEffect(() => {
//     const fetchJobs = async () => {
//       try {
//         const response = await api.get('jobs/', { params: { ordering: '-id', search: debouncedJobSearch } });
//         setJobs(response.data.results.map(job => ({
//           value: job.id,
//           label: job.title,
//         })));
//       } catch (error) {
//         console.error('Error fetching jobs:', error);
//       }
//     };
//     if (debouncedJobSearch) fetchJobs();
//   }, [debouncedJobSearch]);

//   useEffect(() => {
//     const fetchAvatars = async () => {
//       try {
//         const response = await api.get('avatars/', { params: { ordering: 'id', search: debouncedAvatarSearch } });
//         setAvatars(response.data.results.map(avatar => ({
//           value: avatar.id,
//           label: avatar.avatar_name,
//         })));
//       } catch (error) {
//         console.error('Error fetching avatars:', error);
//       }
//     };
//     if (debouncedAvatarSearch) fetchAvatars();
//   }, [debouncedAvatarSearch]);

//   useEffect(() => {
//     const fetchInterviewPlans = async () => {
//       try {
//         const response = await api.get('interviewplans/', { params: { ordering: 'id', search: debouncedInterviewPlanSearch } });
//         setInterviewPlans(response.data.results.map(plan => ({
//           value: plan.id,
//           label: plan.plan_name,
//         })));
//       } catch (error) {
//         console.error('Error fetching interview plans:', error);
//       }
//     };
//     if (debouncedInterviewPlanSearch) fetchInterviewPlans();
//   }, [debouncedInterviewPlanSearch]);

//   const handleSearchChange = (name, value) => {
//     setSearchTerms((prevSearchTerms) => ({
//       ...prevSearchTerms,
//       [name]: value,
//     }));
//   };

//   const handleChange = (name, selectedOption) => {
//     setInterview((prevInterview) => ({
//       ...prevInterview,
//       [name]: selectedOption ? selectedOption.value : null,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const interviewData = {
//       candidate: interview.candidate,
//       job: interview.job,
//       avatar: interview.avatar,
//       difficulty: interview.difficulty,
//       planid: interview.interviewPlan,
//       created_by: userId,
//       usercompany: companyId,
//     };

//     try {
//       await api.post('interviews/', interviewData, { headers: { 'Content-Type': 'application/json' } });
//       alert('Interview created successfully');
//       navigate('/search-interview');
//     } catch (error) {
//       console.error('Error creating interview:', error.response ? error.response.data : error.message);
//       alert('Error creating interview');
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h2>Create Interview</h2>
//       <Form onSubmit={handleSubmit} className="mt-4">
//         <Form.Group controlId="candidate">
//           <Form.Label>Candidate</Form.Label>
//           <Select
//             name="candidate"
//             value={candidates.find((option) => option.value === interview.candidate)}
//             onInputChange={(value) => handleSearchChange('candidate', value)}
//             onChange={(option) => handleChange('candidate', option)}
//             options={candidates}
//             isSearchable
//             placeholder="Search and select Candidate"
//           />
//         </Form.Group>
//         <Form.Group controlId="job">
//           <br/>
//           <Form.Label>Job</Form.Label>
//           <Select
//             name="job"
//             value={jobs.find((option) => option.value === interview.job)}
//             onInputChange={(value) => handleSearchChange('job', value)}
//             onChange={(option) => handleChange('job', option)}
//             options={jobs}
//             isSearchable
//             placeholder="Search and select Job"
//           />
//         </Form.Group>
//         <br/>

//         <Form.Group controlId="interviewPlan">
//           <Form.Label>Interview Plan</Form.Label>
//           <Select
//             name="interviewPlan"
//             value={interviewPlans.find((option) => option.value === interview.interviewPlan)}
//             onInputChange={(value) => handleSearchChange('interviewPlan', value)}
//             onChange={(option) => handleChange('interviewPlan', option)}
//             options={interviewPlans}
//             isSearchable
//             placeholder="Search and select Interview Plan"
//           />
//         </Form.Group>
//         <br/>

//         <Form.Group controlId="avatar">
//           <Form.Label>Avatar</Form.Label>
//           <Select
//             name="avatar"
//             value={avatars.find((option) => option.value === interview.avatar)}
//             onInputChange={(value) => handleSearchChange('avatar', value)}
//             onChange={(option) => handleChange('avatar', option)}
//             options={avatars}
//             isSearchable
//             placeholder="Search and select Avatar"
//           />
//         </Form.Group>
//         <br/>

//         <Form.Group controlId="difficulty">
//           <Form.Label>Difficulty</Form.Label>
//           <Form.Control as="select" name="difficulty" value={interview.difficulty} onChange={(e) => handleChange(e.target.name, { value: parseInt(e.target.value) })} required>
//             <option value={1}>Normal</option>
//             <option value={2}>Hard</option>
//             <option value={3}>Very Hard</option>
//           </Form.Control>
//         </Form.Group>
//         <Button variant="primary" type="submit" className="mt-3">
//           Create Interview
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default CreateInterview;



