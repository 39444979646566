import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Form, InputGroup, Pagination, Badge } from 'react-bootstrap';
import api from '../../api';
import Modal from './Modal'; // Adjust the path based on your file structure
import moment from 'moment'; // Import moment.js for date formatting
import '../../styles.css'; // Adjust the path to import style.css from the src folder
import { getUserId, getCompanyId} from '../../utils/auth'; // Import the utility function
import 'bootstrap-icons/font/bootstrap-icons.css';


const SearchInterviews = () => {
  const [interviews, setInterviews] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [link, setLink] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchInterviews(currentPage);
  }, [currentPage]);

  const fetchInterviews = async (page, searchTerm = '') => {
    try {
      const response = await api.get('/api/searchinterviews/', {
        params: {
          page: page,
          page_size: 15,  // Adjust the page size as needed
          search: searchTerm,  // Add the search term to the request parameters
          usercompany: getCompanyId(),
        },
      });
      setInterviews(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));  // Assuming page_size is 10
    } catch (error) {
      console.error('Error fetching interviews:', error);
    }
  };
  
  const handleSearch = (e) => {
    e.preventDefault();
    fetchInterviews(1, searchTerm); // Pass the search term when fetching interviews
  };

  const handleEdit = (id) => {
    navigate(`/edit-interview/${id}`);
  };

  const renderStatus = (interview_started, interview_ended) => {
    if (interview_started === 0 && interview_ended === 0) {
      return <Badge bg="secondary">Scheduled</Badge>;
    }
    if (interview_started === 1 && interview_ended === 0) {
      return <Badge bg="success">Live</Badge>;
    }
    if (interview_started === 1 && interview_ended === 1) {
      return <Badge bg="primary">Completed</Badge>;
    }
    if (interview_started === 1 && interview_ended === 2) {
      return <Badge bg="danger">Terminated</Badge>;
    }
    return null;
  };

  const handleLink = (encryptedlink) => {
    const fulllink = `https://air.bitsoft.com/?lnk=${encryptedlink}`;
    setLink(fulllink);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container mt-5">
    <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Search Interviews</h2>

        {/* Create Interview Button */}
        <Button
          variant="success"
          onClick={() => navigate('/create-interview')}
          style={{ minWidth: '140px' }}
        >
          <i className="bi bi-plus-lg me-2"></i> {/* Bootstrap icon with right margin */}
          Interview
        </Button>
      </div>

      <Form onSubmit={handleSearch}>
        <InputGroup className="mb-4">
          <Form.Control
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by candidate, job, or avatar"
          />
          <Button variant="primary" type="submit">
            <i className="bi bi-search me-2"></i> Search
          </Button>
        </InputGroup>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Candidate</th>
            <th>Job</th>
            <th>Interview Plan</th>
            <th>Avatar</th>
            <th>Status</th>
            {/* <th>Difficulty</th> */}
            <th>Created By</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {interviews.length > 0 ? (
            interviews.map((interview) => (
              <tr key={interview.id}>
                <td>
                  <a href={`/candidate-details/${interview.candidate_id}`} target="_blank" rel="noopener noreferrer">
                    {interview.candidate_first_name} {interview.candidate_last_name}
                  </a>
                </td>
                <td>
                  <a href={`/job-details/${interview.job_id}`} target="_blank" rel="noopener noreferrer">
                    {interview.job_title}
                  </a>
                </td>
                <td>{interview.interview_plan}</td>
                <td>
                    {interview.avatar_name}
                </td>
                <td>{renderStatus(interview.interview_started, interview.interview_ended)}</td>
                {/* <td>{interview.difficulty_text}</td> */}
                <td>{interview.created_by_firstname} {interview.created_by_lastname}</td>
                <td>{moment(interview.created_at).format('MM/DD/YY HH:mm')}</td>
                <td>
                  <Button size="sm" variant="primary" onClick={() => handleEdit(interview.id)} disabled={interview.interview_started} className={interview.interview_started ? 'disabled-button' : ''}>
                    Edit
                  </Button>
                  <Button size="sm" variant="secondary" onClick={() => handleLink(interview.encryptedlink)}>
                    Link
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                No interviews found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    {/* Pagination */}
    {totalPages > 1 && (
      <Pagination className="justify-content-center">
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

        {/* Show Ellipsis if current page is far from the first page */}
        {currentPage > 2 && <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>}
        {currentPage > 3 && <Pagination.Ellipsis />}

        {/* Display current, previous, and next page numbers */}
        {Array.from({ length: totalPages }, (_, i) => i + 1)
          .filter((page) => page >= currentPage - 1 && page <= currentPage + 1)
          .map((page) => (
            <Pagination.Item key={page} active={page === currentPage} onClick={() => handlePageChange(page)}>
              {page}
            </Pagination.Item>
          ))}

        {/* Show Ellipsis if current page is far from the last page */}
        {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
        {currentPage < totalPages - 1 && (
          <Pagination.Item onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>
        )}

        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
    )}
          <Modal isOpen={isModalOpen} onClose={closeModal} link={link} />
    </div>
  );
};

export default SearchInterviews;


