import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card, Modal } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import api from '../../api';
import { getUserId, getCompanyId} from '../../utils/auth'; // Import the utility function

const EditInterviewPlan = () => {
  const { id } = useParams();
  const [plan, setPlan] = useState({ plan_name: '', description: '', created_by: 0, usercompany: 0 });
  const [instructions, setInstructions] = useState([]);
  const [showVariables, setShowVariables] = useState(false);
  const [variablesContent, setVariablesContent] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch existing plan and instructions, ordered by InstructionOrder
    const fetchPlanDetails = async () => {
      try {
        const planResponse = await api.get(`api/edit-interview-plans/${id}/`);
        const instructionsResponse = await api.get(`api/edit-plan-instructions/${id}/`);
        
        // Sort instructions by InstructionOrder
        const sortedInstructions = instructionsResponse.data.sort((a, b) => a.instruction_order - b.instruction_order);

        setPlan(planResponse.data);
        setInstructions(sortedInstructions);
      } catch (error) {
        console.error('Error fetching plan details:', error);
      }
    };

    fetchPlanDetails();
  }, [id]);

  const handlePlanChange = (e) => {
    const { name, value } = e.target;
    setPlan({ ...plan, [name]: value });
  };


  const handleInstructionChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const newInstructions = [...instructions];
  
    // If it's a checkbox, store 1 for checked and 0 for unchecked
    if (type === 'checkbox') {
      newInstructions[index][name] = checked ? 1 : 0;
    } else {
      // Otherwise, parse the value for specific fields or store the string value
      newInstructions[index][name] = (name === 'actionid' || name === 'aiuser' || name === 'aiuser1' || name === 'tentative_duration') ? parseInt(value, 10) : value;
    }
  
    setInstructions(newInstructions);
  };


  const handleAddInstruction = () => {
    setInstructions([
      ...instructions, 
      { 
        actionid: 1, 
        explaination: '', 
        aiuser: 1, 
        aiuser_instruction: '', 
        aiuser1: 1, 
        aiuser1_instruction: '', 
        tentative_duration: 200,
        instruction_order: instructions.length + 1, // Set order to last
        translate:0
      }
    ]);
  };

  const handleRemoveInstruction = (index) => {
    const updatedInstructions = instructions.filter((_, idx) => idx !== index);
    setInstructions(updatedInstructions);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedInstructions = Array.from(instructions);
    const [movedItem] = reorderedInstructions.splice(result.source.index, 1);
    reorderedInstructions.splice(result.destination.index, 0, movedItem);

    // Update InstructionOrder based on new order
    const updatedInstructions = reorderedInstructions.map((instruction, index) => ({
      ...instruction,
      instruction_order: index + 1
    }));

    setInstructions(updatedInstructions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Update the plan
      await api.put(`api/edit-interview-plans/${id}/`, plan);

      // Submit the reordered instructions with their updated InstructionOrder
      await api.put(`api/edit-plan-instructions/${id}/`, instructions);

      alert('Interview Plan updated successfully');
      navigate('/search-interview-plan');
    } catch (error) {
      console.error('Error updating Interview Plan:', error);
      alert('Error updating Interview Plan');
    }
  };


  const handleSaveAsNew = async () => {
    try {
      // Prepare the new plan data
      const newPlan = {
        ...plan,
        plan_name: `${plan.plan_name} (Copy)`, // Optionally append "(Copy)" to the new plan's name
        created_by: getUserId(), // Set the new created_by field to the current user
        usercompany: getCompanyId(), 
        created_at: null, // Remove timestamp information
        updated_at: null, // Remove timestamp information
      };
  
      // Assign instruction_order to each instruction
      const instructionsWithOrder = instructions.map((instruction, index) => ({
        ...instruction,
        id: null, // Remove the existing instruction ID to treat them as new
        instruction_order: index + 1,  // Assign new order
      }));
  
      // Prepare data for the API
      const dataToSubmit = { ...newPlan, instructions: instructionsWithOrder };
  
      // Send data to the new API endpoint to create a new plan
      const response = await api.post('api/create_interview_plan_with_instructions/', dataToSubmit);
  
      console.log(response);
      alert('New Interview Plan created successfully');
      navigate('/search-interview-plan');
    } catch (error) {
      console.error('Error saving as new Interview Plan:', error.response ? error.response.data : error.message);
      alert('Error saving as new Interview Plan');
    }
  };
  


  const fetchVariables = async () => {
    try {
      const response = await fetch('/variables.txt');
      const text = await response.text();
      setVariablesContent(text);
    } catch (error) {
      console.error('Error fetching variables:', error);
    }
  };

  const handleShowVariables = () => {
    fetchVariables();
    setShowVariables(true);
  };

  const handleCloseVariables = () => setShowVariables(false);

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="8">
          <Card>
            <Card.Body>
              <Card.Title className="text-center mb-4">Edit Interview Plan</Card.Title>
              <div className="d-flex justify-content-end">
                <Button variant="secondary" onClick={handleShowVariables} className="ml-2">
                  Variables
                </Button>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="planName" className="mb-3">
                  <Form.Label>Plan Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="plan_name"
                    value={plan.plan_name}
                    onChange={handlePlanChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>Plan Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={plan.description}
                    onChange={handlePlanChange}
                    required
                  />
                </Form.Group>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="instructions">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {instructions.map((instruction, index) => (
                          <Draggable key={instruction.id} draggableId={`item-${instruction.id}`} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="mb-2"
                              >
                                <Card>
                                  <Card.Body style={{ backgroundColor: index % 2 === 0 ? '#f6f6f6' : '#ffffff' }}>
                                    <h6 className="text-center">Plan Instruction</h6>
                                    <Row>
                                      <Col md="12" className="d-flex justify-content-between align-items-center">
                                        <Form.Group controlId={`actionid-${index}`} className="mb-3">
                                          <Form.Label>Action</Form.Label>
                                          <Form.Control
                                            as="select"
                                            name="actionid"
                                            value={instruction.actionid}
                                            onChange={(e) => handleInstructionChange(index, e)}
                                          >
                                            <option value="1">1 Speak Text Only</option>
                                            <option value="2">2 Speak and Listen</option>
                                            <option value="3">3 Instruction to AI</option>
                                            <option value="4">4 Reserved. Do Not Use</option>
                                          </Form.Control>
                                        </Form.Group>
                                        <Button variant="danger" onClick={() => handleRemoveInstruction(index)}>Remove</Button>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="12">
                                        <Form.Group controlId={`explaination-${index}`} className="mb-3">
                                          <Form.Label>Explanation of this Instruction</Form.Label>
                                          <Form.Control
                                            type="text"
                                            name="explaination"
                                            value={instruction.explaination}
                                            onChange={(e) => handleInstructionChange(index, e)}
                                            required
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="6">
                                        <Form.Group controlId={`aiuser-${index}`} className="mb-3">
                                          <Form.Label>AI User (Select System)</Form.Label>
                                          <Form.Control
                                            as="select"
                                            name="aiuser"
                                            value={instruction.aiuser}
                                            onChange={(e) => handleInstructionChange(index, e)}
                                          >
                                            <option value="1">System</option>
                                            <option value="2">User</option>
                                          </Form.Control>
                                        </Form.Group>
                                      </Col>
                                      <Col md="6">
                                        <Form.Group controlId={`aiuser_instruction-${index}`} className="mb-3">
                                          <Form.Label>Instruction to be Executed (Mandatory)</Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            name="aiuser_instruction"
                                            value={instruction.aiuser_instruction}
                                            onChange={(e) => handleInstructionChange(index, e)}
                                            required
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="6">
                                        <Form.Group controlId={`aiuser1-${index}`} className="mb-3">
                                          <Form.Label>AI User 1 (Select System)</Form.Label>
                                          <Form.Control
                                            as="select"
                                            name="aiuser1"
                                            value={instruction.aiuser1}
                                            onChange={(e) => handleInstructionChange(index, e)}
                                          >
                                            <option value="1">System</option>
                                            <option value="2">User</option>
                                          </Form.Control>
                                        </Form.Group>
                                      </Col>
                                      <Col md="6">
                                        <Form.Group controlId={`aiuser1_instruction-${index}`} className="mb-3">
                                          <Form.Label>Additional Instruction (Leave blank)</Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            name="aiuser1_instruction"
                                            value={instruction.aiuser1_instruction}
                                            onChange={(e) => handleInstructionChange(index, e)}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="6">
                                        <Form.Group controlId={`tentative_duration-${index}`} className="mb-3">
                                          <Form.Label>Tentative Duration</Form.Label>
                                          <Form.Control
                                            type="number"
                                            name="tentative_duration"
                                            value={instruction.tentative_duration}
                                            onChange={(e) => handleInstructionChange(index, e)}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="12">
                                        <Form.Group controlId={`confirm-${index}`} className="mb-3">
                                          <Form.Check
                                            type="checkbox"
                                            label="Translate Instruction from English to Avatar language"
                                            name="translate"
                                            checked={instruction.translate === 1}
                                            onChange={(e) => handleInstructionChange(index, {
                                              target: {
                                                name: 'translate',
                                                value: e.target.checked ? 1 : 0,  // Convert checked state to 1 or 0
                                              }
                                            })}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                  </Card.Body>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className="d-flex justify-content-end">
                  <Button variant="primary" onClick={handleAddInstruction} className="w-50 mt-3">
                    Add New Instruction
                  </Button>
                </div>
                <hr className="my-4" />
                <Button variant="success" type="submit" className="w-100 mt-3">
                    Update Interview Plan
                </Button>
                <Button variant="warning" onClick={handleSaveAsNew} className="w-100 mt-3">
                    Save As New Plan
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showVariables} onHide={handleCloseVariables} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Variables</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{variablesContent}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVariables}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EditInterviewPlan;

