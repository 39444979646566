// src/components/Users/SearchUsers.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Form, Pagination, InputGroup } from 'react-bootstrap';
import api from '../../api';
import { getUserId, getCompanyId } from '../../utils/auth'; // Import the utility function

const SearchUsers = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const fetchUsers = async (page, searchTerm = '') => {
    try {
      const response = await api.get('users/', {
        params: {
          page: page,
          page_size: 15, // Adjust the page size as needed
          usercompany: getCompanyId(),
          search: searchTerm,
        },
      });
      setUsers(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 15));
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchUsers(1, searchTerm); // Reset to page 1 when searching
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Search Users</h2>

        {/* Create Interview Button */}
        <Button
          variant="success"
          onClick={() => navigate('/add-user')}
          style={{ minWidth: '100px' }}
        >
          <i className="bi bi-plus-lg me-2"></i> {/* Bootstrap icon with right margin */}
          User
        </Button>
      </div>      
      <Form className="mb-4" onSubmit={handleSearch}>
        <InputGroup>
          <Form.Control
            type="text"
            placeholder="Search by name, job title, or client"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button variant="primary" type="submit">
            <i className="bi bi-search me-2"></i> Search
          </Button>
        </InputGroup>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users.map((user) => (
              <tr key={user.id}>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>
                  <Link to={`/edit-user/${user.id}`} className="btn btn-primary btn-sm">
                    Edit
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">No users found</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination */}
      {totalPages > 1 && (
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

          {currentPage > 2 && <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>}
          {currentPage > 3 && <Pagination.Ellipsis />}

          {Array.from({ length: totalPages }, (_, i) => i + 1)
            .filter((page) => page >= currentPage - 1 && page <= currentPage + 1)
            .map((page) => (
              <Pagination.Item key={page} active={page === currentPage} onClick={() => handlePageChange(page)}>
                {page}
              </Pagination.Item>
            ))}

          {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
          {currentPage < totalPages - 1 && (
            <Pagination.Item onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>
          )}

          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      )}
    </div>
  );
};

export default SearchUsers;





// // src/components/Users/SearchUsers.js
// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { Table, Button, Form } from 'react-bootstrap';
// import api from '../../api';
// import { getUserId, getCompanyId} from '../../utils/auth'; // Import the utility function

// const SearchUsers = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const fetchUsers = async () => {
//     try {
//       // const response = await api.get('users/');
//       const response = await api.get('users/', {
//         params: {
//           usercompany: getCompanyId(),  // Pass company ID as a query parameter
//         },
//       });
//       setUsers(response.data.results);
//     } catch (error) {
//       console.error('Error fetching users:', error);
//     }
//   };

//   const handleSearch = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await api.get(`users/?search=${searchTerm}`);
//       setUsers(response.data.results);
//     } catch (error) {
//       console.error('Error searching users:', error);
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h2>Search Users</h2>
//       <Form onSubmit={handleSearch} className="mb-4">
//         <Form.Group controlId="searchTerm">
//           <Form.Control
//             type="text"
//             placeholder="Search users by username or email"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//         </Form.Group>
//         <Button variant="primary" type="submit" className="mt-2">
//           Search
//         </Button>
//       </Form>
//       <Table striped bordered hover>
//         <thead>
//           <tr>
//             <th>Username</th>
//             <th>Email</th>
//             <th>First Name</th>
//             <th>Last Name</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {users.map((user) => (
//             <tr key={user.id}>
//               <td>{user.username}</td>
//               <td>{user.email}</td>
//               <td>{user.first_name}</td>
//               <td>{user.last_name}</td>
//               <td>
//                 <Link to={`/edit-user/${user.id}`} className="btn btn-primary btn-sm">
//                   Edit
//                 </Link>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

// export default SearchUsers;
