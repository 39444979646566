// src/components/Reports/Report3.js
import React from 'react';

const Report3 = () => {
  return (
    <div className="container mt-5">
      <h2>Report 3</h2>
      <p>Content for Report 3 goes here...</p>
    </div>
  );
};

export default Report3;
