// import React, { useState } from 'react';
// import api from '../../api';

// const CheckAIResponse = () => {
//   const [loading, setLoading] = useState(false);
//   const [response, setResponse] = useState(null);
//   const [error, setError] = useState(null);

//   const handleStartClick = async () => {
//     setLoading(true);
//     setError(null);
//     setResponse(null);

//     try {
//       const data = {
//         numberfrom: 700, // Replace with your actual 'numberfrom' value
//         numberto: 720,   // Replace with your actual 'numberto' value
//       };

//       const result = await api.post('Check_AI_Response/', data);

//       setResponse(result.data);
//     } catch (err) {
//       setError(`Error: ${err.response ? err.response.data : err.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       <button onClick={handleStartClick} disabled={loading}>
//         {loading ? 'Processing...' : 'Start'}
//       </button>
      
//       {response && (
//         <div>
//           <h3>Success:</h3>
//           <pre>{JSON.stringify(response, null, 2)}</pre>
//         </div>
//       )}

//       {error && (
//         <div>
//           <h3>Error:</h3>
//           <pre>{error}</pre>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CheckAIResponse;





// src/components/Reports/Report1.js
import React from 'react';

const Report1 = () => {
  return (
    <div className="container mt-5">
      <h2>Report 1</h2>
      <p>Content for Report 1 goes here...</p>
    </div>
  );
};

export default Report1;



