// src/components/Reports/Report4.js
import React from 'react';

const Report4 = () => {
  return (
    <div className="container mt-5">
      <h2>Report 4</h2>
      <p>Content for Report 4 goes here...</p>
    </div>
  );
};

export default Report4;
