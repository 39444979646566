// src/components/Jobs/JobDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';

const JobDetails = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  console.log(id);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await api.get(`jobs/${id}/`);
        setJob(response.data);
      } catch (error) {
        console.error('Error fetching job:', error);
      }
    };

    fetchJob();
  }, [id]);

  if (!job) return <div>Loading...</div>;

  return (
    <div className="container mt-5">
      <h2>Job Details</h2>
      <p>Title: {job.title}</p>
      {/* <p>Description: {job.description}</p> */}
      <p>Location: {job.location}</p>
      <p>Skills: {job.skills}</p>
      <p>Duration: {job.duration}</p>
      <p>Rate: {job.rate}</p>
      <p>Company: {job.company}</p>
      <p>Posted By: {job.posted_by}</p>
      <p>Posted Date: {new Date(job.posted_date).toLocaleString()}</p>
      <br></br>
      <h2>Description</h2>
      <textarea 
        value={job.description} 
        readOnly 
        rows={10} 
        style={{ 
          width: '100%', 
          whiteSpace: 'pre-wrap', 
          border: 'none', 
          resize: 'none', 
          outline: 'none',
          backgroundColor: 'transparent',  // Makes the background transparent
          padding: 0  // Removes padding
        }} 
      />

    </div>
  );
};

export default JobDetails;
