// src/components/Avatars/AvatarDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';

const AvatarDetails = () => {
  const { id } = useParams();
  const [avatar, setAvatar] = useState(null);
  const [languageDetails, setLanguageDetails] = useState(null); // State for the language details

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const response = await api.get(`avatars/${id}/`);
        setAvatar(response.data);
      } catch (error) {
        console.error('Error fetching avatar:', error);
      }
    };

    const fetchLanguageDetails = async (languageCode) => {
      try {
        // API call to get specific row from language array using avatar.language as a parameter
        const response = await api.get(`api/languagevalues/?language_code=${languageCode}`);
        setLanguageDetails(response.data); // Set language details from the API response
      } catch (error) {
        console.error('Error fetching language details:', error);
      }
    };

    fetchAvatar();
    fetchLanguageDetails(avatar.language);
  }, [id]);

  if (!avatar) return <div>Loading...</div>;

  return (
<div className="container mt-5">
  <h2>Avatar Details</h2>
  <p>Avatar Name: {avatar.avatar_name}</p>
  <p>First Name: {avatar.avatar_firstname}</p>
  <p>Description: {avatar.description}</p>
  <p>Language: languageDetails[2]</p>
  <p>Voice Name: languageDetails[4]</p>
  <p>Gender: languageDetails[5]</p>
  <p>Avatar Style: {avatar.avatar_style}</p>
  <p>Avatar Face: languageDetails[6]</p>
  <p>Animation Type: {avatar.avatar_animationtype}</p>
  <p>Background: {avatar.background}</p>
  <p>Camera Style: {avatar.camera_style}</p>
  <p>Iconset: {avatar.iconset}</p>
  <p>Buttonset: {avatar.buttonset}</p>
  <p>Pitch: {avatar.pitch}</p>
  <p>Speech Rate: {avatar.rate}</p>
  <p>Volume: {avatar.volume}</p>
  <p>Expressive Style: {avatar.expressive_style}</p>
  <p>Emotional Style: {avatar.emotional_style}</p>
</div>
  );
};

export default AvatarDetails;
