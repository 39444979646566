// src/components/Users/AddUser.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import api from '../../api';
import { getUserId, getCompanyId } from '../../utils/auth'; // Import the utility function

const AddUser = () => {
  const userId = getUserId();
  const companyId = getCompanyId();
    
  const [user, setUser] = useState({
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    is_staff: false,
    is_enabled: true,
    password: '',
    confirm_password: '',
    created_by: userId, // Add created_by field with userId
    company: companyId,
    
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.password !== user.confirm_password) {
      alert("Passwords don't match");
      return;
    }
    try {
      // await api.post('users/', user);
      await api.post('create_user/', user);

      alert('User added successfully');
      navigate('/search-users');
    } catch (error) {
      console.error('Error adding user:', error);
      alert('Error adding user');
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="8">
          <Card>
            <Card.Body>
              <Card.Title className="text-center mb-4">Add User</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="username" className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    value={user.username}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="first_name" className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={user.first_name}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="last_name" className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={user.last_name}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="is_staff" className="mb-3">
                  <Form.Check
                    type="checkbox"
                    name="is_staff"
                    label="Is Staff"
                    checked={user.is_staff}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="is_enabled" className="mb-3">
                  <Form.Check
                    type="checkbox"
                    name="is_enabled"
                    label="Is Enabled"
                    checked={user.is_enabled}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="password" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="confirm_password" className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirm_password"
                    value={user.confirm_password}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 mt-3">
                  Add User
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddUser;
