import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Rnd } from 'react-rnd';
import { Table, Button, Card, Container, Row, Col, Accordion, ProgressBar, Tab, Nav } from 'react-bootstrap';

import api from '../../api';
import Hls from 'hls.js';
import moment from 'moment';
import './InterviewDetail.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  RadialLinearScale, // For Radar
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

import { Bar, Line, Radar, Pie } from 'react-chartjs-2';

// Register necessary components
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  Filler
);


const InterviewDetail = () => {
  const { id } = useParams();
  const [interview, setInterview] = useState(null);
  const [responses, setResponses] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [isVideoFloating, setIsVideoFloating] = useState(false);
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);

  const isSafari = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes('safari') && !userAgent.includes('chrome');
  };

  useEffect(() => {
    const fetchInterviewDetail = async () => {
      try {
        const response = await api.get(`/api/get_interview_details/${id}/`);
        setInterview(response.data[0]);
      } catch (error) {
        console.error('Error fetching interview details:', error);
      }
    };

    const fetchInterviewResponses = async () => {
      try {
        const response = await api.get(`/api/get_interview_responses/${id}/`);
        setResponses(response.data);
      } catch (error) {
        console.error('Error fetching interview responses:', error);
      }
    };

    fetchInterviewDetail();
    fetchInterviewResponses();
  }, [id]);

  useEffect(() => {
    if (videoUrl && videoRef.current) {
      initializeVideo(videoUrl);
    }
  }, [videoUrl]);

  const initializeVideo = (url) => {
    setIsVideoFloating(true);
    if (isSafari()) {
      if (videoRef.current) {
        videoRef.current.src = url;
        videoRef.current.play();
      }
    } else if (Hls.isSupported()) {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoRef.current.play();
      });
      hlsRef.current = hls;
    } else {
      if (videoRef.current) {
        videoRef.current.src = url;
        videoRef.current.play();
      }
    }
  };

  const playVideo = (url) => {
    setVideoUrl(url);
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    const x = windowWidth - 700 + scrollX;
    const y = windowHeight - 580 + scrollY;

    setXPosition(x);
    setYPosition(y);

    setIsVideoFloating(true);
  };

  const closeVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.removeAttribute('src');
      videoRef.current.load();
    }
    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }
    setIsVideoFloating(false);
  };


  const sampleBehaviorData = {
    visual: {
      emotions: [
        { neutral: 0.8, happy: 0.1, sad: 0.05, angry: 0.03, fearful: 0.01, disgusted: 0.005, surprised: 0.005 },
        { neutral: 0.75, happy: 0.15, sad: 0.04, angry: 0.03, fearful: 0.01, disgusted: 0.01, surprised: 0.005 },
      ],
    },
  };



  const extractEmotions = (data) => {
    if (!Array.isArray(data)) {
      console.error('Data is not an array:', data);
      return [];
    }
  
    const extractedData = [];
  
    data.forEach((entry, entryIndex) => {
      const emotionsArray = entry?.visual?.emotions || [];
      if (!Array.isArray(emotionsArray) || emotionsArray.length === 0) {
        console.warn(`No emotions found for entry at index ${entryIndex}:`, emotionsArray);
        return;
      }
  
      emotionsArray.forEach((emotion, emotionIndex) => {
        extractedData.push({
          time: `T${entryIndex + 1}_${emotionIndex + 1}`, // Custom time label for clarity
          Neutral: emotion.neutral || 0,
          Happy: emotion.happy || 0,
          Sad: emotion.sad || 0,
          Angry: emotion.angry || 0,
          Fearful: emotion.fearful || 0,
          Disgusted: emotion.disgusted || 0,
          Surprised: emotion.surprised || 0,
        });
      });
    });
  
    return extractedData;
  };
  


  const extractPostureData = (data) => {
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return [];
    }
  
    const postureData = [];
  
    data.forEach((entry, index) => {
      const posture = entry?.visual?.posture || "unknown"; // Check if posture exists, default to "unknown"
      postureData.push({
        time: `T${index + 1}`, // Custom time label for each entry
        posture,
      });
    });

    // console.log(postureData);
  
    return postureData;
  };


  const extractEngagementData = (data) => {
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return [];
    }
  
    const engagementData = [];
    data.forEach((entry, index) => {
      const engagementScore = entry?.engagement?.score;
      if (typeof engagementScore !== 'number') {
        console.warn(`Engagement score at index ${index} is missing or invalid. Skipping.`);
        return;
      }
      engagementData.push({
        time: `T${index + 1}`, // Custom time label for visualization
        score: engagementScore,
      });
    });
    return engagementData;
  };
  

  const extractGazeData = (data) => {
    if (!Array.isArray(data)) {
      console.error("Input data is not an array:", data);
      return [];
    }
  
    // Initialize counters for gaze directions
    const gazeZones = { left: 0, center: 0, right: 0 };
  
    data.forEach((entry, index) => {
      const gazeDirection = entry?.visual?.gazeDirection;
  
      if (typeof gazeDirection !== 'string') {
        console.warn(`Gaze direction at index ${index} is missing or invalid. Skipping.`);
        return;
      }
  
      // Increment the zone count for the given gaze direction
      if (gazeZones.hasOwnProperty(gazeDirection)) {
        gazeZones[gazeDirection] += 1;
      } else {
        gazeZones[gazeDirection] = 1; // Handle any unexpected directions
      }
    });
  
    // Convert gazeZones object into chart-ready format
    return {
      labels: Object.keys(gazeZones),
      values: Object.values(gazeZones),
    };
  };



  const BehaviorAnalysisPanel = ({ behaviorData }) => {
    if (!behaviorData || typeof behaviorData !== 'object') {
      return <p>No behavior analysis data available.</p>;
    }
  
    const emotionChartData = extractEmotions(behaviorData);
    const gazeChartData = extractGazeData(behaviorData);
    const postureChartData = extractPostureData(behaviorData);
    const engagementChartData = extractEngagementData(behaviorData);

    // Emotion Chart
    const emotionLabels = emotionChartData.map((data) => data.time);
    const emotionDatasets = [
      { label: 'Neutral', data: emotionChartData.map((data) => data.Neutral), backgroundColor: '#d9d9d9' },
      { label: 'Happy', data: emotionChartData.map((data) => data.Happy), backgroundColor: '#f0ad4e' },
      { label: 'Sad', data: emotionChartData.map((data) => data.Sad), backgroundColor: '#5bc0de' },
      { label: 'Angry', data: emotionChartData.map((data) => data.Angry), backgroundColor: '#d9534f' },
      { label: 'Fearful', data: emotionChartData.map((data) => data.Fearful), backgroundColor: '#292b2c' },
      { label: 'Disgusted', data: emotionChartData.map((data) => data.Disgusted), backgroundColor: '#5cb85c' },
      { label: 'Surprised', data: emotionChartData.map((data) => data.Surprised), backgroundColor: '#0275d8' },
    ];
    const emotionData = { labels: emotionLabels, datasets: emotionDatasets };
    const emotionOptions = {
      aspectRatio: 6,
      responsive: true,
      plugins: { legend: { position: 'top' } },
      scales: { x: { stacked: true }, y: { stacked: true } },
    };
  
    // Engagement Line Chart
    const engagementLabels = engagementChartData.map((data) => data.time);
    const engagementValues = engagementChartData.map((data) => data.score);
    const engagementData = {
      labels: engagementLabels,
      datasets: [
        {
          label: 'Engagement Score',
          data: engagementValues,
          borderColor: '#ffa500',
          backgroundColor: 'rgba(255, 165, 0, 0.2)',
          fill: true,
        },
      ],
    };
    const engagementOptions = {
      aspectRatio: 6,
      responsive: true,
      plugins: { legend: { position: 'top' } },
      scales: { x: { title: { display: true, text: 'Time' } }, y: { title: { display: true, text: 'Engagement Score' } } },
    };
  
    // Gaze Pie Chart
    
    const gazePieData = {
      labels: gazeChartData.labels,
      datasets: [
        {
          data: gazeChartData.values,
          backgroundColor: ['#76c7c0', '#f7b267', '#f25f5c'], // Assign colors for left, center, right
        },
      ],
    };
  
    // Options for the pie chart
    const gazePieOptions = {
      aspectRatio: 6,
      responsive: true,
      plugins: {
        legend: { position: 'top' },
        tooltip: {
          callbacks: {
            label: (context) => {
              const total = gazeChartData.values.reduce((sum, value) => sum + value, 0);
              const percentage = ((context.raw / total) * 100).toFixed(2);
              return `${context.label}: ${context.raw} (${percentage}%)`;
            },
          },
        },
      },
    };
  
    // Posture Chart
    const postureCounts = postureChartData.reduce((acc, { posture }) => {
      acc[posture] = (acc[posture] || 0) + 1;
      return acc;
    }, {});
    const postureLabels = Object.keys(postureCounts);
    const postureValues = Object.values(postureCounts);
    const postureColors = { upright: '#FF9999', slouched: '#99FF99', unknown: '#9999FF' };
    const postureData = {
      labels: postureLabels,
      datasets: [{ data: postureValues, backgroundColor: postureLabels.map((label) => postureColors[label] || '#9999FF') }],
    };
    const postureOptions = { aspectRatio: 6, responsive: true, plugins: { legend: { position: 'top' } } };
  
    return (
      <Tab.Container defaultActiveKey="engagement">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="engagement">Engagement</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="gaze">Gaze Directions</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="emotions">Emotions</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="posture">Posture</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="engagement">
            <h6>Engagement Over Time</h6>
            <Line data={engagementData} options={engagementOptions} />
          </Tab.Pane>
          <Tab.Pane eventKey="gaze">
            <h6>Proportion of Gaze Zones</h6>
            <Pie data={gazePieData} options={gazePieOptions} />
            </Tab.Pane>
          <Tab.Pane eventKey="emotions">
            <h6>Emotions Over Time</h6>
            <Bar data={emotionData} options={emotionOptions} />
          </Tab.Pane>
          <Tab.Pane eventKey="posture">
            <h6>Posture Distribution</h6>
            <Pie data={postureData} options={postureOptions} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    );
  };
  

  
  return (
    <Container className="interview-detail mt-5">
      <Row className="justify-content-center">
        <Col md={11}>
          <Card className="shadow-lg border-0 mb-5">
            <Card.Body className="p-4">
              <Card.Title className="text-center mb-4 display-6">
                Interview Details
              </Card.Title>
              {interview && (
                <div className="interview-info">
                  <p>
                    <strong>Candidate:</strong> {interview.candidate.first_name}{" "}
                    {interview.candidate.last_name}
                  </p>
                  <p>
                    <strong>Job Title:</strong> {interview.job.title}
                  </p>
                  <p>
                    <strong>Location:</strong> {interview.job.location}
                  </p>
                  <p>
                    <strong>Client:</strong> {interview.job.company}
                  </p>
                </div>
              )}
            </Card.Body>
          </Card>

          <h5 className="text-center mb-4">Responses</h5>
          <Table
            striped
            bordered
            hover
            responsive
            className="shadow-sm rounded"
          >
            <thead style={{ backgroundColor: "#6c757d", color: "white" }}>
              <tr>
                <th>Question and Response</th>
                <th>AI Score</th>
                <th>Start At</th>
                <th>Video</th>
              </tr>
            </thead>
            <tbody>
              {responses.map((response) => (
                <tr key={response.id}>
                  <td>
                      <div style={{ marginBottom: "8px" }}>
                        <strong style={{ fontSize: "1.1em", color: "#333" }}>
                          <i className="bi bi-question-circle-fill" style={{ marginRight: "5px", color: "#6c757d" }}></i>
                          Question {response.questionnumber}:
                        </strong>{" "}
                        <span style={{ fontWeight: "normal", color: "#555" }}>{response.question_text}</span>
                      </div>
                      <div style={{ marginBottom: "8px" }}>
                        <strong style={{ color: "#007bff" }}>
                          <i className="bi bi-chat-left-dots-fill" style={{ marginRight: "5px" }}></i>
                          Response:
                        </strong>{" "}
                        <span>{response.response_text}</span>
                      </div>
                      <div style={{ marginBottom: "8px" }}>
                        <strong style={{ color: "#28a745" }}>
                          <i className="bi bi-bar-chart-fill" style={{ marginRight: "5px" }}></i>
                          AI Analysis:
                        </strong>{" "}
                        <span>{response.AIAnalysis}</span>
                      </div>
                      <div style={{ marginTop: "12px"}}>
                        <BehaviorAnalysisPanel behaviorData={response.behavior} />
                      </div>
                  </td>

                  <td className="text-center">{response.AIPoints}</td>
                  <td>
                    {moment(response.questionstarttime).format(
                      "MM/DD/YY HH:mm:ss"
                    )}
                  </td>
                  <td className="text-center">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => playVideo(response.video_url)}
                      disabled={!response.video_url || response.video_url === ""}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {isVideoFloating && (
            <Rnd
              default={{
                width: 480,
                height: 360,
                x: xPosition,
                y: yPosition,
              }}
              dragHandleClassName="video-drag-handle"
              className="floating-video shadow"
              style={{
                position: "fixed",
                zIndex: 1000,
              }}
            >
              <div className="video-container rounded bg-white">
                <div className="video-header d-flex justify-content-between align-items-center p-2">
                  <span className="video-drag-handle">Drag Me</span>
                  <button className="btn-close" onClick={closeVideo}></button>
                </div>
                <video
                  ref={videoRef}
                  controls
                  width="100%"
                  height="100%"
                  className="rounded"
                >
                  {isSafari() ? (
                    <source src={videoUrl} type="application/x-mpegURL" />
                  ) : null}
                </video>
              </div>
            </Rnd>
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default InterviewDetail;


// import React, { useState, useEffect, useRef } from 'react';
// import { useParams } from 'react-router-dom';
// import { Rnd } from 'react-rnd';  // For draggable and resizable components
// import { Table, Button, Card, Container, Row, Col } from 'react-bootstrap';  // For displaying data
// import api from '../../api';
// import Hls from 'hls.js';  // Import Hls.js for HLS streaming
// import moment from 'moment';
// //import './FloatingVideo.css';  // Custom styles for floating video
//  import './InterviewDetail.css';  // Custom styles for floating video

// const InterviewDetail = () => {
//   const { id } = useParams();
//   const [interview, setInterview] = useState(null);
//   const [responses, setResponses] = useState([]);
//   const [videoUrl, setVideoUrl] = useState('');  // URL of the video to play
//   const [isVideoFloating, setIsVideoFloating] = useState(false);
//   const videoRef = useRef(null);  // Reference to the video element
//   const hlsRef = useRef(null);    // Reference to Hls.js instance

// // *************************************************************
// const [xPosition, setXPosition] = useState(0);
// const [yPosition, setYPosition] = useState(0);
// // **************************************************************




//   // Detect if the browser is Safari
//   const isSafari = () => {
//     const userAgent = navigator.userAgent.toLowerCase();
//     return userAgent.includes('safari') && !userAgent.includes('chrome');
//   };




//   useEffect(() => {
//     // Fetch interview details and responses
//     const fetchInterviewDetail = async () => {
//       try {
//         const response = await api.get(`/api/get_interview_details/${id}/`);
//         setInterview(response.data[0]); 
//       } catch (error) {
//         console.error('Error fetching interview details:', error);
//       }
//     };

//     const fetchInterviewResponses = async () => {
//       try {
//         const response = await api.get(`/api/get_interview_responses/${id}/`);
//         setResponses(response.data);
//       } catch (error) {
//         console.error('Error fetching interview responses:', error);
//       }
//     };

//     fetchInterviewDetail();
//     fetchInterviewResponses();
//   }, [id]);

//   useEffect(() => {
//     // Reinitialize the video whenever the video URL changes
//     if (videoUrl && videoRef.current) {
//       initializeVideo(videoUrl);
//     }
//     // }, [videoUrl]);
//   },);

//   const initializeVideo = (url) => {
//     setIsVideoFloating(true);  // Show the floating video player

//     if (isSafari()) {
//       // Safari supports HLS natively
//       if (videoRef.current) {
//         videoRef.current.src = url;
//         videoRef.current.play();  // Auto-play for Safari
//       }
//     } else if (Hls.isSupported()) {
//       // Non-Safari browsers: use Hls.js for HLS streaming
//       if (hlsRef.current) {
//         hlsRef.current.destroy();  // Clean up any previous HLS instance
//       }
//       const hls = new Hls();
//       hls.loadSource(url);  // Load the HLS URL
//       hls.attachMedia(videoRef.current);  // Attach HLS to the video element
//       hls.on(Hls.Events.MANIFEST_PARSED, () => {
//         videoRef.current.play();  // Auto-play when manifest is parsed
//       });
//       hlsRef.current = hls;  // Store the Hls.js instance
//     } else {
//       // Fallback for unsupported browsers
//       if (videoRef.current) {
//         videoRef.current.src = url;
//         videoRef.current.play();
//       }
//     }
//   };

//   const playVideo = (url) => {
//     setVideoUrl(url);  // Set the video URL when button is clicked

//     const windowWidth = window.innerWidth;
//     const windowHeight = window.innerHeight;
//     const scrollX = window.scrollX;
//     const scrollY = window.scrollY;

//     // Set x and y to place the video in the bottom-right corner of the browser
//     const x = windowWidth - 700 + scrollX; // 20px from the right edge
//     const y = windowHeight - 580 + scrollY; // 20px from the bottom edge

//     setXPosition(x);
//     setYPosition(y);

//     setIsVideoFloating(true);  // Show the floating video window
//   };

//   const closeVideo = () => {
//     if (videoRef.current) {
//       videoRef.current.pause();  // Pause the video
//       videoRef.current.removeAttribute('src');  // Remove the video source to stop playback
//       videoRef.current.load();  // Reset the video element
//     }

//     if (hlsRef.current) {
//       hlsRef.current.destroy();  // Destroy the hls.js instance when closing
//       hlsRef.current = null;
//     }

//     // Hide the floating video player
//     setIsVideoFloating(false);
//   };

//   return (
//     <Container className="interview-detail">
//       <Row className="justify-content-center">
//         <Col md={12}>
//           <Card className="shadow-sm mb-6">
//             <Card.Body>
//               <Card.Title className="text-center mb-8">Interview Details</Card.Title>
//               {interview && (
//                 <div className="interview-info">
//                   <p><strong>Candidate:</strong> {interview.candidate.first_name} {interview.candidate.last_name}</p>
//                   <p><strong>Job Title:</strong> {interview.job.title}</p>
//                   <p><strong>Location:</strong> {interview.job.location}</p>
//                   <p><strong>Client:</strong> {interview.job.company}</p>
//                 </div>
//               )}
//             </Card.Body>
//           </Card>

//           <h5 className="text-center">Responses</h5>
//           <Table striped bordered hover className="responses-table">
//             <thead>
//               <tr>
//                 <th>Question Number</th>
//                 <th>Question and Response</th>
//                 <th>Analysis</th>
//                 <th>Points</th>
//                 <th>Question Start Time</th>
//                 <th>Video</th>
//               </tr>
//             </thead>
//             <tbody>
//               {responses.map((response) => (
//                 <tr key={response.id}>
//                   <td>{response.questionnumber}</td>
//                   <td>
//                     <strong>Question:</strong> {response.question_text}
//                     <br />
//                     <strong>Response:</strong> {response.response_text}
//                   </td>
//                   <td>{response.AIAnalysis}</td>
//                   <td>{response.AIPoints}</td>
//                   <td>{moment(response.questionstarttime).format('MM/DD/YY HH:mm:ss')}</td>
//                   <td>
//                     <Button
//                       variant="primary"
//                       size="sm"
//                       onClick={() => playVideo(response.video_url)}  // Set the video URL when button is clicked
//                       disabled={!response.video_url || response.video_url === ""}
//                     >
//                       Play Video
//                     </Button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>

//           {isVideoFloating && (
//           <Rnd
//           default={{
//             width: 480,
//             height: 360,
//             x: xPosition,
//             y: yPosition,
//           }}
//           dragHandleClassName="video-drag-handle"
//           className="floating-video"
//           style={{
//             position: 'fixed', 
//             zIndex: 1000,
//           }}
          
//         >

//           <div className="video-container">
//             <div className="video-header">
//               <span className="video-drag-handle">Drag Me</span>
//               <button className="close-button" onClick={closeVideo}>✖</button>
//             </div>
//             <video
//               ref={videoRef}
//               controls
//               width="100%"
//               height="100%"
//             >
//               {isSafari() ? (
//                 <source src={videoUrl} type="application/x-mpegURL" />
//               ) : null}
//             </video>
//           </div>
//         </Rnd>
//           )}
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default InterviewDetail;


