// // src/components/Reports/Report2.js
// import React from 'react';

// const Report2 = () => {
//   return (
//     <div className="container mt-5">
//       <h2>Report 2</h2>
//       <p>Content for Report 2 goes here...</p>
//     </div>
//   );
// };

// export default Report2;


import React, { useState } from 'react';
import api from '../../api';

const App = () => {
  const [uploadStatuses, setUploadStatuses] = useState([]); // Store an array of statuses

  const handleUpload = async () => {
    const statuses = [];
    setUploadStatuses(['Uploading...']); // Show initial upload message

    for (let i = 1; i <= 5000; i++) {
      try {
        // const response = await api.get(`/api/azure_to_cloudflare_upload/`);

        const response = await api.get(`/api/local_to_cloudflare_upload/`);

        if (response.data.status === 'success') {
          statuses.push(`Upload ${i}: successful!`);
        } else {
          statuses.push(`Upload ${i}: failed. Please try again.`);
        }
      } catch (error) {
        console.error('Error uploading:', error);
        statuses.push(`Upload ${i}: Error occurred during upload.`);
      }

      // Update the status after each call
      setUploadStatuses([...statuses]);
    }
  };

  return (
    <div>
      <h1>Azure to Cloudflare Upload</h1>
      <button onClick={handleUpload}>Upload 500 Times</button>
      <div>
        {uploadStatuses.map((status, index) => (
          <p key={index}>{status}</p>
        ))}
      </div>
    </div>
  );
};

export default App;
